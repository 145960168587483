/*
  Params:
    endpoint -> name of the endpoint

    pin -> the rest pin, used for authneticated API calls

    success -> success callback, receieves the json decoded response from endpoint

    failure -> error call back, receives the error

    data -> post data (should be oejct)

    url_uparams -> url parameters as object for GET requests

    resource_id -> appeneded to the end of a URL 
*/

const callApi = (params) => {
  let endpoint = params.endpoint
  const pin = params.pin ? params.pin : null
  const success = params.success ? params.success : () => {}
  const failure = params.failure ? params.failure : () => {}
  const data = params.data ? params.data : {}
  // const url_params = data.url_params ? data.url_params : {};
  const resource_id = params.resource_id ? params.resource_id : null
  const order_id = params.order_id ? params.order_id : null
  let apiContent = "json"

  let method = ""
  if (endpoint === "rests") {
    method = "post"
    endpoint = "/foood/rests"
  }

  if (endpoint === "rest-info") {
    method = "get"
    endpoint = `/foood/rests/${params.id}`
  }
  if (endpoint === "clickstream") {
    endpoint = "/clickstream"
    method = "post"
  }

  if (endpoint === "search") {
    endpoint = `/foood/typeahead?latitude=${params.url_params.lat}&longitude=${params.url_params.lng}&q=${params.url_params.query}&type=${params.url_params.search_type}&limit=${params.url_params.limit}&logo_required=${params.url_params.logo_required}&independent_required=${params.url_params.independent_required}`
    method = "get"
  }

  if (endpoint === "reverse_geocode") {
    method = "get"
    endpoint = `/api/reverse-geocode?lat=${params.url_params.lat}&lng=${params.url_params.lng}`
  }

  const url = `${process.env.REACT_APP_FOODCOIN_API_LOCATION}${endpoint}`
  if (method === "post") {
    const headers = {
      "Content-Type": "application/json",
      "Clickstream-Session-Id": window.CLICKSTREAM_SESSION_ID,
      "X-HNGR-API-KEY": window.API_KEY,
      "X-HNGR-ORDER-ID": order_id,
    }
    if (pin) {
      headers["x-api-key"] = pin
    }
    if (apiContent === "json") {
      fetch(url, { method: "post", headers, body: JSON.stringify(data) })
        .then((jsonresponse) => jsonresponse.json())
        .then((response) => {
          success(response)
        })
        .catch((err) => {
          console.log(err)
          failure(err)
        })
    } else if (apiContent === "blob") {
      fetch(url, { method: "post", headers, body: JSON.stringify(data) })
        .then((response) => response.blob())
        .then((response) => {
          success(response)
        })
        .catch((err) => {
          console.log(err)
          failure(err)
        })
    }
  }

  if (method === "get") {
    const headers = {
      "X-HNGR-API-KEY": window.API_KEY,
      "Clickstream-Session-Id": window.CLICKSTREAM_SESSION_ID,
    }
    if (apiContent === "json") {
      fetch(url, { method: "get", headers })
        .then((jsonresponse) => jsonresponse.json())
        .then((response) => {
          success(response)
        })
        .catch((err) => {
          console.log(err)
          failure(err)
        })
    } else if (apiContent === "blob") {
      fetch(url, { method: "get", headers })
        .then((response) => response.blob())
        .then((response) => {
          success(response)
        })
        .catch((err) => {
          console.log(err)
          failure(err)
        })
    }
  }
}

module.exports = {
  callApi,
}
