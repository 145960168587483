import React, { useEffect, useState } from "react"
import classnames from "classnames"
import { isEmpty, filter } from "lodash"
import { Link } from "react-router-dom"
import Analytics from "analytics"
import { Text } from "components"
import { callApi } from "helpers/api"
import { ReactComponent as Smile } from "assets/smile-s.svg"
import { isMobile } from "fileConstants"

const Recommended = ({ currentId, lat, lng, city }) => {
  const [recommended, setRecommended] = useState(null)
  const [status, setStatus] = useState("idle")
  useEffect(() => {
    fetchRecommended()
  }, [currentId])

  const fetchRecommended = async () => {
    setStatus("loading")
    await callApi({
      endpoint: "search",
      url_params: {
        lat: lat,
        lng: lng,
        query: "",
        search_type: "recommended",
        limit: 5,
        logo_required: 1,
        independent_required: 1,
      },
      success: (data) => filterRecommended(data),
    })
  }

  const filterRecommended = (data) => {
    let filtered = filter(data, (rest) => rest.id !== currentId)
    if (filtered.length > 4) {
      filtered = filtered.splice(0, 4)
    }
    setRecommended(filtered)
    setStatus("ready")
  }

  if (status === "loading") {
    return (
      <div className="u-mar_5">
        <div className="rest-recs">
          <div className="restaurant-rec__container u-mar_3 loading-shine loading-shine--white">
            <div className="flex-row flex-align-center">
              <div
                className={classnames(
                  "top-rated__image-place u-mar-b_auto rest-preview__image--bg",
                  {}
                )}
              ></div>
            </div>
          </div>
          <div className="restaurant-rec__container u-mar_3 loading-shine loading-shine--white">
            <div className="flex-row flex-align-center">
              <div
                className={classnames(
                  "top-rated__image-place u-mar-b_auto rest-preview__image--bg",
                  {}
                )}
              ></div>
            </div>
          </div>
          <div className="restaurant-rec__container u-mar_3 loading-shine loading-shine--white">
            <div className="flex-row flex-align-center">
              <div
                className={classnames(
                  "top-rated__image-place u-mar-b_auto rest-preview__image--bg",
                  {}
                )}
              ></div>
            </div>
          </div>
          <div className="restaurant-rec__container u-mar_3 loading-shine loading-shine--white">
            <div className="flex-row flex-align-center">
              <div
                className={classnames(
                  "top-rated__image-place u-mar-b_auto rest-preview__image--bg",
                  {}
                )}
              ></div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const handleRecClick = (name) => {
    Analytics.info("Recommended restaurant clicked", name)
  }

  return (
    !isEmpty(recommended) && (
      <div>
        <Smile className="u-mar-l_3" />
        <Text
          options={{ color: "blue", weight: "bold" }}
          size="m"
          className="u-mar-l_3 u-mar-t_1"
        >
          Other Local Faves
        </Text>
        <div className="rest-recs u-mar-t_3">
          {recommended.map((rest) => {
            const imgsrc = rest.primary_image_url
              ? `${rest.primary_image_url}?size=0x75`
              : null
            return (
              <Link
                key={rest.id}
                to={(location) => {
                  return {
                    pathname: `/discover/${city}/${rest.id}`,
                  }
                }}
                onClick={() => handleRecClick(rest.name)}
                className={isMobile() ? "u-mar-h_3 u-mar-v_1" : "u-mar_3"}
              >
                <div className="restaurant-rec__container">
                  <div className="flex-row flex-align-center">
                    <div
                      className={classnames(
                        "top-rated__image-place u-mar-b_auto",
                        {
                          "rest-preview__image--bg": !imgsrc,
                        }
                      )}
                    >
                      {imgsrc && (
                        <img
                          src={imgsrc}
                          alt={rest.name}
                          className="top-rated-rest__image u-mar-b_auto"
                        />
                      )}
                    </div>
                    <Text
                      options={{ color: "blue", align: "left", weight: "bold" }}
                      size="m"
                      className="u-mar-l_4"
                    >
                      {rest.name}
                    </Text>
                  </div>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    )
  )
}

export default Recommended
