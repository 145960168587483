import React from "react"
import ChangeAddress from "views/Discover/ChangeAddress"
import Search from "views/Discover/Search"
import Manifesto from "views/Home/Manifesto"
import Feedback from "views/Discover/Feedback"

import { toLower, has } from "lodash"
import EmailListModal from "views/Discover/EmailListModal"

export default ({ id }) => {
  const componentId = toLower(id)
  const m = {
    changeaddress: <ChangeAddress />,
    search: <Search />,
    manifesto: <Manifesto />,
    emaillistmodal: <EmailListModal />,
    feedback: <Feedback />,
  }

  if (!has(m, componentId)) {
    throw new Error(
      "Component id must be a valid id. Check ModalReducer.js to configure"
    )
  }

  return m[componentId]
}
