import React, { useEffect } from "react"
import ReactDOM from "react-dom"
import classnames from "classnames"
import PropTypes from "prop-types"
import "./Modal.scss"

const Modal = ({ children, options, closeModal }) => {
  const modalRoot = document.getElementById("modal-root")
  const childRef = React.useRef(null)
  const root = document.getElementById("root")
  useEffect(() => {
    if (options.preventBodyScroll) {
      root.style.overflowY = "hidden"
    }
    document.addEventListener("keydown", handleEsc)
    document.addEventListener("mousedown", handleClick)

    return () => {
      root.style.overflowY = ""
      document.removeEventListener("mousedown", handleClick)
      document.removeEventListener("keydown", handleEsc)
    }
  }, [])

  const handleEsc = (e) => {
    if (e.key === "Escape") {
      closeModal()
    }
  }

  const handleClick = (e) => {
    if (childRef.current) {
      if (!childRef.current.contains(e.target)) {
        closeModal()
      }
    }
  }
  const el = (
    <div
      style={{ backgroundColor: options.bgColor }}
      className={classnames("modal-root__container", {
        "flex-row flex-align-center flex-justify-center": options.centerContent,
        "fill-height": options.fillHeight,
        [options.className]: options.className,
      })}
    >
      <div
        ref={childRef}
        className={classnames("modal-inner", {
          "modal-inner--initial-height": options.initialHeight,
        })}
      >
        {children}
      </div>
    </div>
  )

  return ReactDOM.createPortal(el, modalRoot)
}

Modal.defaultProps = {
  options: {},
}

// Pass in prevent
Modal.propTypes = {
  options: PropTypes.shape({
    preventBodyScroll: PropTypes.bool,
  }),
}

export default Modal
