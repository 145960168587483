import React from "react"
import { Text, Foodmoji } from "components"
import classnames from "classnames"
import HomePageContainer from "./HomePageContainer"
import HomeSignUp from "./HomeSignUp"

import { ReactComponent as FooodBlue } from "assets/foood-blue-large.svg"
import { isMobile } from "fileConstants"

const HomeFour = () => {
  const size = "28x28"

  return (
    <HomePageContainer ignorePadding={!isMobile()} ignoreFluid={!isMobile()}>
      <div
        className={classnames("", {
          "flex-row": !isMobile(),
        })}
      >
        {isMobile() && <HomeSignUp />}
        <div
          className={classnames("flex-col", {
            "flex-b-50 home-four-container home-four-left u-mar-l_auto": !isMobile(),
            "u-mar-t_15": isMobile(),
          })}
        >
          <div className={!isMobile() && "max-80 u-pad-v_20 u-mar-h_auto"}>
            <div className="flex-row flex-apart flex-align-center">
              <FooodBlue />
              <div className="home__foodmojis-container">
                <Foodmoji name="Salad.png" size={size} className="u-mar-h_1" />
                <Foodmoji
                  name="Empanada.png"
                  size={size}
                  className="u-mar-h_1"
                />
                <Foodmoji
                  name="Pancakes.png"
                  size={size}
                  className="u-mar-h_1"
                />
              </div>
            </div>

            <Text
              options={{ color: "blue", weight: "extrabold" }}
              size="l"
              className="u-mar-t_7"
            >
              Why Foood.com?
            </Text>
            <Text
              options={{ color: "blue", weight: "medium" }}
              className="u-mar-t_4"
            >
              We came together as food industry veterans with one common
              mission: to do good for the restaurants we love and help diners
              directly support local businesses.
            </Text>
            <Text
              options={{ color: "blue", weight: "medium" }}
              className="u-mar-t_4"
            >
              We noticed that diners had no central way to discover and support
              these local restaurants, so we created Foood.com, connecting
              diners with great neighborhood restaurants to support local eats
              directly.
            </Text>

            <Text
              options={{ color: "blue", weight: "medium" }}
              className="u-mar-t_4"
            >
              We make it simple to search, discover, and use resources to order
              directly from restaurants in your community. So join us - we’re
              all eating <b>la vida local.</b>
            </Text>
          </div>
        </div>
        {!isMobile() && <HomeSignUp />}
      </div>
    </HomePageContainer>
  )
}

export default HomeFour
