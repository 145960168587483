import React from "react"
import { ReactComponent as Check } from "assets/check-large.svg"
import { ReactComponent as CheckMedium } from "assets/check-medium.svg"
import { ReactComponent as CheckSmall } from "assets/check-small.svg"

const Foodmoji = ({ name, size, ...rest }) => (
  <img
    alt={name}
    src={`https://imgcdn.hngr.co/${name}?size=${size}`}
    {...rest}
  />
)

export const FoodmojiMap = ({ name, size }) => {
  const sizes = { s: "30x30", m: "40x40", l: "65x65" }
  const svgs = {
    s: <CheckSmall />,
    m: <CheckMedium />,
    l: <Check />,
  }
  const svg_sizes = {
    s: { maxHeight: 26, maxWidth: 26 },
    m: { maxHeight: 60, maxWidth: 60 },
  }
  const img_size = sizes[size]
  const m = {
    "Local Faves": {
      title: "Local Favorites",
      description:
        "Neighborhood restaurants need your support, so discover a new favorite or keep coming back to your go-tos.",
      icon: svgs[size],
    },
    "Asian & Indian": {
      title: "Asian & Indian",
      description:
        "From Chinese dishes to Indian spices, bring flavors from beyond the East into your home.",
      icon: <Foodmoji name="Nigiri.png" size={img_size} />,
    },
    American: {
      title: "American",
      description:
        "Bite into a burger or dig into diner favorites. Comfort is king, and classic Americana never tasted so good.",
      icon: <Foodmoji name="Burger.png" size={img_size} />,
    },
    "Mexican & Latin": {
      title: "Mexican & Latin",
      description:
        "Chiles & limes & spices, oh my! Latin flavors come alive, whether you’re craving tacos or looking for more.",
      icon: <Foodmoji name="Taco.png" size={img_size} />,
    },
    "Pizza & Italian": {
      title: "Pizza & Italian",
      description:
        "Game night? Pizza. Date night? Pasta. For every occasion, there’s an Italian favorite waiting for you.",
      icon: <Foodmoji name="Pizza.png" size={img_size} />,
    },
    Mediterranean: {
      title: "Mediterranean",
      description:
        "See delicious visions of shawarma, hummus, and more Mediterranean and Arab delights.",
      icon: <Foodmoji name="Tandoor_Wraps.png" size={img_size} />,
    },
    "Quick Bites": {
      title: "Quick Bites",
      description:
        "On the run? In a rush? These quick bites satisfy in just the nick of time.",
      icon: <Foodmoji name="Soda Can.png" size={img_size} />,
    },
    "Chain Picks": {
      title: "Chain Picks",
      description:
        "Take your pick of local and national chains, including fast food and fast casual.",
      icon: <Foodmoji name="Hot Dog.png" size={img_size} />,
    },
  }

  return m[name]
}

export default Foodmoji
