import React, { useCallback, useState, useRef } from "react"
import classnames from "classnames"
import { round } from "lodash"
import Analytics from "analytics"
import { Text } from "components"
import { ReactComponent as LocationIcon } from "assets/location.svg"
import { ReactComponent as DirectionIcon } from "assets/directions.svg"
import { ReactComponent as DirectionIconWhite } from "assets/directions-white.svg"

const AddressBanner = ({ restData, address, desktop }) => {
  const HOME = address.lat && address.lng ? `${address.lat},${address.lng}` : ""
  const GOOGLE_MAPS_DIRECTION_LINK = `https://www.google.com/maps/dir/?api=1&origin=${HOME}&destination=${restData.coordinates.latitude},${restData.coordinates.longitude}`
  const KEY = process.env.REACT_APP_FOOOD_GOOGLE_API_KEY

  const [dimens, setDimens] = useState({})
  let mapContainer = useRef(null)
  const mapRef = useCallback((node) => {
    if (node !== null) {
      mapContainer.current = node
      let box = node.getBoundingClientRect()
      let width = box.width
      const height = box.height
      console.log(width, height)
      setDimens({ width: width, height })
    }
  }, [])

  const h = round(dimens.width / 2)
  const w = desktop ? dimens.width : dimens.width - 40
  let GOOGLE_MAPS_STATIC = `https://maps.googleapis.com/maps/api/staticmap?center=${restData.coordinates.latitude},${restData.coordinates.longitude}&size=${w}x${h}&markers=${restData.coordinates.latitude},${restData.coordinates.longitude}&maptype=roadmap&key=${KEY}`

  const handleDirectionClick = () => {
    Analytics.info("Restaurant directions clicked", restData.name)
  }

  return (
    <div className={desktop ? "u-mar-t_5" : "bg-white"}>
      <div
        className={classnames("address-banner-container", {
          "u-pad_5": !desktop,
        })}
        ref={mapRef}
      >
        <a
          href={GOOGLE_MAPS_DIRECTION_LINK}
          onClick={handleDirectionClick}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={GOOGLE_MAPS_STATIC} alt="Google map" />
        </a>
        <div className="flex-row flex-align-center u-mar-t_4">
          {!desktop && <LocationIcon className="u-mar-r_3" />}
          <div>
            <Text
              size="s"
              options={{
                weight: "bold",
                align: "left",
                color: desktop ? "white" : "blue",
              }}
            >
              {restData.address1}
            </Text>
            <Text
              size="s"
              options={{
                align: "left",
                color: desktop ? "white" : "blue",
                weight: "medium",
              }}
            >
              {restData.city}, {restData.state} {restData.zipcode}
            </Text>
          </div>
          <a
            className="u-mar-l_auto link-none"
            href={GOOGLE_MAPS_DIRECTION_LINK}
            target="_blank"
            onClick={handleDirectionClick}
            rel="noopener noreferrer"
          >
            <div className="flex-col flex-align-center ">
              {desktop ? <DirectionIconWhite /> : <DirectionIcon />}
              <Text
                size="s"
                options={{ weight: "light", color: desktop ? "white" : "blue" }}
              >
                Directions
              </Text>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default AddressBanner
