import React from "react"
import { times } from "lodash"

const Loading = ({ topRated, other }) => {
  const Top = (
    <div className="top-rated-container top-rated-container--loading u-pad_3">
      <div className="top-rated__image-place top-rated__image-place--loading loading-shine"></div>
      <div className="u-mar-l_2 flex-rcol flex-align-start flex-grow">
        <div className="top-rated-name--loading u-mar-r_2 loading-shine">
          {/* {name} */}
        </div>
        <div className="top-rated-address--loading u-mar-t_3 loading-shine" />
      </div>
    </div>
  )

  const Other = (
    <div className="rest-preview-container rest-preview-container--loading u-pad_2 u-mar-v_3">
      <div className="flex-row flex-align-start">
        <div className="rest-preview__image-placeholder rest-preview__image-placeholder--loading loading-shine" />
        <div className="u-mar-l_2 flex-rcol flex-align-start flex-grow">
          <div className="top-rated-name--loading u-mar-r_2 loading-shine">
            {/* {name} */}
          </div>
          <div className="top-rated-address--loading u-mar-t_3 loading-shine" />
        </div>
      </div>
    </div>
  )
  if (topRated) return Top
  return Other
}

export default Loading
