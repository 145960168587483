import React, { Component } from "react"
import classnames from "classnames"
import extractAddressComponents from "../../helpers/googlePlaces"

import PlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete"
import { ReactComponent as EnterIcon } from "assets/address-enter.svg"
import { ReactComponent as ClearIcon } from "assets/clear.svg"
import "./GooglePlacesAutocomplete.css"
import ButtonWrapper from "components/ButtonWrapper/ButtonWrapper"

class GooglePlacesAutocomplete extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: window.GOOGLE_MAPS_LOADED,
      formattedAddress: this.props.formattedAddress
        ? this.props.formattedAddress
        : "",
      address: this.props.address ? this.props.address : {},
    }
    this.input = React.createRef()

    if (!window.GOOGLE_MAPS_LOADED) {
      this.checkIfGoogleMapsIsLoaded()
    }
  }

  componentDidMount() {
    window.addEventListener("keydown", this.handleEnter)
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.address &&
      this.state.formattedAddress == null &&
      prevProps.formattedAddress !== this.state.address
    ) {
      this.setState({ formattedAddress: prevProps.formattedAddress })
    }
    if (!prevProps.address && this.props.address) {
      this.setState({ address: this.props.address })
    }
    if (prevProps.formattedAddress && !this.props.formattedAddress) {
      this.setState({ formattedAddress: "" })
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleEnter)
  }

  handleEnter = (e) => {
    if (e.keyCode === 13 && this.props.address && this.state.formattedAddress) {
      const { address } = this.props
      if (address.address1 && address.lat && address.lng && address.city) {
        this.props.onSelect(address)
      }
    }
  }

  checkIfGoogleMapsIsLoaded() {
    if (window.GOOGLE_MAPS_LOADED) {
      this.setState({ loaded: true })
    } else {
      setTimeout(() => this.checkIfGoogleMapsIsLoaded(), 10)
    }
  }

  handleClear = () => {
    this.setState({ formattedAddress: "" }, () => {
      if (this.input) {
        this.input.focus()
      }
    })
  }

  handleChange = (address) => {
    if (address.length < this.state.formattedAddress.length) {
      this.props.handleAddressBackspace()
    }
    if (!this.props.addressNotSelected && this.props.updateAddressError) {
      if (this.props.updateAddressError) {
        this.props.updateAddressError(true)
      }
    }
    this.setState({ formattedAddress: address })
  }

  handleSelect = (address, place_id) => {
    this.setState({ address: address })
    var that = this
    geocodeByPlaceId(place_id)
      .then((results) => {
        that.formattedAddress = results[0].formatted_address
        that.addressComponents = extractAddressComponents(
          results[0].address_components,
          address,
          place_id
        )
        return getLatLng(results[0])
      })
      .then((latLng) => {
        let addressObj = {
          formattedAddress: that.formattedAddress,
          ...latLng,
          ...that.addressComponents,
          place_id: place_id,
        }
        that.props.onSelect(addressObj)
        that.setState({ formattedAddress: that.formattedAddress })
        if (this.props.updateAddressError) {
          this.props.updateAddressError(false)
        }
      })
      .catch((error) => {
        console.error("Error", error)
        this.setState({ address: null })
      })
  }

  renderNoAddressMessage(suggestions, loading) {
    if (
      suggestions.length === 0 &&
      this.state.formattedAddress &&
      !this.state.address &&
      !loading
    ) {
      return (
        <div className="NoAddressMessage">
          Address not found! Try again and select an address from the dropdown.
          We'll get your apartment number during checkout.
        </div>
      )
    }
  }

  render() {
    if (!this.state.loaded) return null
    return (
      <div className="autocompleteContainer">
        <div className="autocompleteWrapper">
          <PlacesAutocomplete
            style={styles.container}
            value={this.state.formattedAddress}
            onChange={this.handleChange}
            onSelect={this.handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div
                style={{
                  display: "inline-block",
                  width: "100%",
                  textAlign: "left",
                  background: "#fff",
                }}
              >
                <form autoComplete="off">
                <div className="location-search__container">
                  <EnterIcon className="location-search-icon" />
                  <input
                    ref={(el) => (this.input = el)}
                    {...getInputProps({
                      placeholder: "Enter your address",
                      className: classnames(
                        `location-search-input u-pad_3 u-pad-h_10`,
                        {
                          [`location-search-input--${this.props.align}`]: this
                            .props.align,
                          "location-search-input--dark": this.props.lightBg,
                        }
                      ),
                    })}
                  />
                  {this.state.formattedAddress && (
                    <ButtonWrapper
                      className="location-search-clear"
                      onClick={this.handleClear}
                    >
                      <ClearIcon />
                    </ButtonWrapper>
                  )}
                </div>
                <div
                  className="autocomplete-dropdown-container"
                  // style={{
                  //   position: "absolute",
                  //   width: "calc(100% - 0px)",
                  //   border: "1px solid #eee",
                  //   borderTop: "0",
                  //   top: "0",
                  //   left: "-1px",
                  // }}
                >
                  {loading && (
                    <div style={{ backgroundColor: "white" }}>Loading...</div>
                  )}
                  {this.renderNoAddressMessage(suggestions, loading)}
                  {suggestions.length > 0 &&
                    suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item"
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className: className,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      )
                    })}
                </div>
                </form>
              </div>
            )}
          </PlacesAutocomplete>
        </div>
      </div>
    )
  }
}
const styles = {
  addressReturn: {
    height: "50px",
    backgroundColor: "white",
    width: "94%",
    marginLeft: "3%",
    marginRight: "3%",
    borderRadius: "10px",
    boxShadow: "1px 1px 1px .5px lightgray",
    marginTop: "20px",
    marginBottom: "10px",
  },
  spacer: {
    height: "10px",
    display: "block",
  },
  goButton: {
    backgroundColor: "green",
    display: "inline-block",
    border: "0px",
    color: "white",
    height: "50px",
    borderRadius: "10px",
    fontWeight: "300",
    fontSize: "12pt",
    zIndex: "980",
    paddingLeft: "20px",
    paddingRight: "20px",
    cursor: "pointer",
    boxShadow: "1px 1px 10px 4px lightgray",
    marginTop: "20px",
    width: "350px",
  },
  errorReturn: {
    position: "fixed",
    top: "300px",
    backgroundColor: "white",
    width: "80%",
    boxShadow: "1px 1px 10px .5px lightgray",
    zIndex: "990",
    textAlign: "center",
    borderRadius: "10px",
    padding: "15px",
    marginLeft: "6%",
  },
  padding: {
    marginBottom: "20px",
    marginTop: "10px",
    marginLeft: "3%",
    width: "94%",
    marginRight: "3%",
  },
  background: {
    backgroundColor: "whitesmoke",
    width: "100%",
    height: "100%",
    position: "fixed",
    opacity: ".9",
    marginTop: "-280px",
    zIndex: "900",
    textAlign: "center",
  },
  inlineBlock: {
    display: "inline-block",
  },
  container: {
    display: "inline-block",
    textAlign: "center",
  },
}
export default GooglePlacesAutocomplete
