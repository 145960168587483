import React from "react"
import { Link } from "react-router-dom"
import Analytics from "analytics"
import { Text, ButtonWrapper } from "components"
import Socials from "./Socials"
import { routeNames } from "fileConstants"
import HomePageContainer from "./HomePageContainer"
const HomeFooter = () => {
  return (
    <HomePageContainer className="bg-blue">
      <footer className="fill-width flex-row flex-apart home-footer">
        <div className="flex-col flex-apart">
          <div>
            <Text options={{ color: "white", weight: "bold" }}>Foood.com</Text>
            <div className=" flex-col u-mar-t_3">
              <ButtonWrapper
                onClick={() =>
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                }
              >
                <Text
                  options={{ color: "white", align: "left", weight: "medium" }}
                  size="s"
                >
                  Home
                </Text>
              </ButtonWrapper>
              <Link
                to="/manifesto"
                className="link-none"
                onClick={() => Analytics.info("Manifesto opened")}
              >
                <ButtonWrapper>
                  <Text
                    options={{
                      color: "white",
                      align: "left",
                      weight: "medium",
                    }}
                    className="u-mar-t_2"
                    size="s"
                  >
                    Manifesto
                  </Text>
                </ButtonWrapper>
              </Link>
              <Link to={routeNames.CONTACT} className="link-none">
                <Text
                  options={{ color: "white", align: "left", weight: "medium" }}
                  className="u-mar-t_2"
                  size="s"
                >
                  Restaurant Contact
                </Text>
              </Link>
            </div>
          </div>
        </div>
        <div className="home-footer__two flex-col flex-align-end">
          <Socials />
          <Text
            options={{ color: "white", align: "right", weight: "bold" }}
            className="u-mar-t_5"
          >
            Do you own a restaurant?
          </Text>
          <Link to={routeNames.CONTACT}>
            <ButtonWrapper
              fillColor="pink"
              className="u-pad-v_3 u-pad-h_10 br-5 u-mar-t_3"
            >
              <Text
                options={{ color: "white", align: "right", weight: "bold" }}
                size="s"
              >
                CONTACT US
              </Text>
            </ButtonWrapper>
          </Link>
        </div>
      </footer>
      <Text
        options={{ color: "white", align: "left", weight: "bold" }}
        className="u-mar-t_15"
      >
        © 2020 Foood. All Rights Reserved.
      </Text>
    </HomePageContainer>
  )
}

export default HomeFooter
