class IO {
  constructor() {
    this.observer = null
    this.construct({ callback: () => {} })
  }
  construct = ({ callback }) => {
    if (IntersectionObserver) {
      console.log("contriced IO")
      this.observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            // when image is visible in the viewport + rootMargin
            //!entry.target.src to make sure we don't rereuqest images if we've already loaded
            if (
              entry.isIntersecting &&
              (!entry.target.src ||
                entry.target.src !== entry.target.dataset.src)
            ) {
              const lazyImage = entry.target
              console.log("observed")
              lazyImage.src = lazyImage.dataset.src
              if (callback) {
                callback()
              }
            }
          })
        },
        {
          threshold: 0.01,
          rootMargin: "75%",
        }
      )

      return this
    }
  }

  observe = (target) => {
    console.log("observing")
    this.observer.observe(target)
    this.targets.push(target)
  }

  targets = []
  destroy = () => {
    if (this.observer && this.observer.unobserve) {
      this.targets.forEach((target) => {
        this.observer.unobserve(target)
      })
    }

    this.targets = []
  }
  reobserve = () => {
    this.destroy()
    console.log(this.targets)
    this.construct({ callback: null })
  }
}

export default IO
