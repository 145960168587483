import React, { useRef, useCallback } from "react"
import { Link } from "react-router-dom"
import { round, random } from "lodash"
import classnames from "classnames"
import { isMobile } from "fileConstants"
import Analytics from "analytics"
import { Text } from "components"
import { ReactComponent as Arrow } from "assets/Arrow.svg"
import { ReactComponent as CheckExtraSmall } from "assets/check-xs.svg"
import { ReactComponent as Nologoup } from "assets/no-logo-up.svg"
import { ReactComponent as Nologoright } from "assets/no-logo-right.svg"
import { ReactComponent as Nologoleft } from "assets/no-logo-left.svg"
import { ReactComponent as Nologodown } from "assets/no-logo-down.svg"
import "./TopRatedRest.scss"

const s = isMobile() ? 63 : 75

const logos = [
  <Nologoup width={s} height={s} />,
  <Nologoright width={s} height={s} />,
  <Nologoleft width={s} height={s} />,
  <Nologodown width={s} height={s} />,
]

const TopRatedRest = (props) => {
  const { observer, isTopRated } = props
  const {
    name,
    distance,
    direct_ordering,
    pink_checkmark,
    address1,
    id,
    primary_image_url,
  } = props.rest
  const roundedDist = round(distance, 1)
  const randomIndex = random(0, 4)
  let laptopEl = useRef(null)
  const imageRef = useCallback(
    (node) => {
      if (node !== null && observer) {
        laptopEl.current = node
        observer.observe(node)
      }
    },
    [primary_image_url, observer]
  )

  const size = isMobile() ? "0x63" : "0x75"
  const imgsrc = primary_image_url ? `${primary_image_url}?size=${size}` : null

  const srcProperty = observer
    ? { "data-src": imgsrc, src: null }
    : { src: imgsrc }
  return (
    <Link
      onClick={() => Analytics.info("restaurant clicked", name)}
      to={(location) => {
        return {
          pathname: `${location.pathname}/${id}`,
        }
      }}
      className={classnames("top-rated-container u-pad-l_2", {
        "top-rated-container--border": isTopRated,
      })}
    >
      <div className="top-rated__image-place">
        {imgsrc ? (
          <img
            {...srcProperty}
            alt={name}
            ref={imageRef}
            className="top-rated-rest__image"
          />
        ) : (
          logos[randomIndex]
        )}
      </div>
      <div className="u-mar-l_2 flex-rcol flex-align-start flex-grow">
        <Text
          className="u-mar-r_2"
          options={{ weight: isMobile() ? "extrabold" : "bold", color: "blue" }}
          size={isMobile() ? "m" : "l"}
        >
          {name}
        </Text>
        <Text options={{ weight: "medium", color: "blue" }} size="s">
          {address1}
        </Text>
        {pink_checkmark && <CheckExtraSmall style={{ minWidth: 14 }} />}
      </div>
      <div className="flex-row flex-align-center u-mar-v_auto">
        <Text
          size="s"
          options={{ weight: "light", color: "blue" }}
          className="u-mar-r_1 no-wrap"
        >
          {roundedDist} mi
        </Text>
        <Arrow />
      </div>
    </Link>
  )
}
export default TopRatedRest
