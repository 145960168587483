import React, { useState, useContext } from "react"
import classnames from "classnames"
import Analytics from "analytics"
import GooglePlacesAutocomplete from "components/GooglePlacesAutocomplete/GooglePlacesAutocomplete"
import { Text } from "components"
import { AppContext } from "context/AppContext"
import LocalStorage from "helpers/LocalStorage"
import { ReactComponent as CurLocIcon } from "assets/current-location.svg"
import { isMobile } from "fileConstants"

const AddressComponents = (props) => {
  const [loadingGps, setLoadingGps] = useState(false)
  let coords = null
  const { address, setAddress, reverseGeo } = useContext(AppContext)

  const dropdownSelect = (addressObj) => {
    Analytics.info(
      "Google places dropdown clicked",
      addressObj.formattedAddress
    )
    setAddress(addressObj)
    LocalStorage.update(addressObj)
    props.dropdownSelectCallback(addressObj)

    // invoke call to hngr analytics
    let payload = {'physical_address': addressObj.formattedAddress}
    window.hngr.send('FOOOD', 'ADDRESS_SEARCH', payload)

  }

  const currentLocationSuccess = (position) => {
    coords = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    }
    const cb = (data) => {
      LocalStorage.update(data)
      props.dropdownSelectCallback(data)
      Analytics.info("Current location pressed", data.formattedAddress)
    }
    reverseGeo({ coords: coords, callback: cb })
  }

  const currentLocation = () => {
    setLoadingGps(true)
    navigator.geolocation.getCurrentPosition(
      (position) => currentLocationSuccess(position),
      () => {}
    )
  }

  const formatted = address.address1
    ? address.address1
    : address.formattedAddress
  return (
    <div
      className={classnames("home__actions-container", {
        [props.className]: props.className,
      })}
    >
      <GooglePlacesAutocomplete
        updateError={() => {}}
        address={address}
        align={props.align}
        formattedAddress={formatted ? formatted : ""}
        onSelect={dropdownSelect}
        currentLocation={currentLocation}
        isLoading={loadingGps}
        handleAddressBackspace={() => {}}
        lightBg={props.lightBg}
      />
      <Text
        className={classnames("home__actions-divide u-mar-v_3", {
          "home__actions--dark": props.lightBg,
        })}
        options={{ weight: "bold" }}
      >
        or
      </Text>
      <button
        className={classnames("home__actions-current", {
          "u-pad_3": isMobile(),
          "home__actions-current--loading": loadingGps,
          "home__actions-curent--dark": props.lightBg,
        })}
        onClick={currentLocation}
      >
        <CurLocIcon className="home__actions-current-icon" />
        <Text size="m" options={{ align: "center" }}>
          {loadingGps ? "Loooading..." : "Use my location"}
        </Text>
      </button>
    </div>
  )
}
AddressComponents.defaultProps = {
  dropdownSelectCallback: () => {},
  align: "center",
}
export default AddressComponents
