import React, { useEffect, useContext, useState } from "react"
import _ from "lodash"
import classnames from "classnames"
import { CSSTransitionGroup } from "react-transition-group"
import { useHistory } from "react-router-dom"
import { AppContext } from "context/AppContext"
import { ModalContext } from "context/ModalContext"
import Analytics from "analytics"
import { routeNames, isMobile } from "fileConstants"
import EmptyRests from "./EmptyRests"
import { ReactComponent as BackToTop } from "assets/back-to-top.svg"
import DirectBanner from "./DirectBanner"
import {
  Stories,
  FixedHeader,
  Header,
  LocalBanner,
  Text,
  ButtonWrapper,
  FoodmojiMap,
} from "components"
import { useQuery } from "helpers/useQuery"

import { ReactComponent as DropdownChevron } from "assets/chevron-down.svg"
import RestFeed from "./RestFeed"
import "./Discover.scss"

const HEADER_HEIGHT = 130
const SCROLL_TOP = 550

const Feed = () => {
  const [showDirectBanner, setShowDirectBanner] = useState(true)
  const {
    address,
    isLoading,
    savedCategory,
    setInitialAddress,
    fetchRests,
    stateReducer,
    rests,
  } = useContext(AppContext)
  const { openModal } = useContext(ModalContext)
  const query = useQuery()
  const history = useHistory()

  // This is the same as the inital mount of the component
  useEffect(() => {
    // No address entered, redirect hte user to the homepage to enter their address
    if (!query.has("lat") || !query.has("lng") || !query.has("a")) {
      history.push(routeNames.HOME)
      return
    }
    if (_.isEmpty(address)) {
      setInitialAddress()
    }

    if (_.isEmpty(rests)) {
      fetchRests({
        lat: query.get("lat"),
        lng: query.get("lng"),
      })
    }

    Analytics.pageview()
  }, [])

  // use Callback to refetch restaurants when a new address is entered
  const loadDatacb = (data) => {
    const { lat, lng } = data
    const query = new URLSearchParams(window.location.search)
    history.push({
      pathname: `/discover/search`,
      search: `?lat=${lat}&lng=${lng}&a=${query.get('a')}`,
    })
    fetchRests({
      lat,
      lng,
    })
  }

  /*
    This is akin to the callback function that used to be passed into setState({} , here)
  */
  useEffect(() => {
    //check to see if they've set an initial category before
    const setInitialCategory = () => {
      if (!savedCategory) {
        // If they haven't selected a category yet, set it to the first one
        const firstCategory = _.keys(rests)[0]
        setCategory(firstCategory)
      }
    }

    //if we've loaded the data, set the category
    if (!_.isEmpty(rests)) {
      setInitialCategory()
    }
  }, [rests])

  const setCategory = (cat) => {
    stateReducer({ field: "savedCategory", value: cat })
    window.scrollTo(0, 0)
  }

  return (
    <div>
      {showDirectBanner && (
        <DirectBanner key={0} closeBanner={() => setShowDirectBanner(false)} />
      )}

      <div
        className={classnames("feed-container fluid-content-l fill-height", {
          "nav-margin": !showDirectBanner,
          "direct-banner-margin ": showDirectBanner && !isMobile(),
        })}
      >
        <CSSTransitionGroup
          transitionAppear={true}
          transitionEnter={false}
          transitionLeave={true}
          transitionLeaveTimeout={300}
          transitionAppearTimeout={1000}
          transitionName="direct-banner"
        ></CSSTransitionGroup>
        <div
          className={classnames("u-pad-t_3 feed-header__container", {
            "u-pad-h_3 u-pad-b_10": !isMobile(),
          })}
        >
          <div className="fluid-content">
            <div
              className={classnames({
                "flex-row flex-apart u-mar-h_3 flex-align-center": isMobile(),
                "flex-col flex-align-start u-pad-h_2": !isMobile(),
              })}
            >
              <Header
                title={address.city}
                size="s"
                options={{ color: "blue", weight: "bold" }}
              />
              <ButtonWrapper
                onClick={() => {
                  openModal({
                    id: "ChangeAddress",
                    customProps: {
                      dropdownSelectCallback: loadDatacb,
                    },
                    options: { preventBodyScroll: true, bgColor: "white" },
                  })
                }}
                className={classnames("flex-row flex-align-center", {
                  "u-mar-l_3": isMobile(),
                })}
              >
                {address.address1 && <Text
                  size="s"
                  options={{
                    align: isMobile() ? "right" : "left",
                    weight: "medium",
                    color: "blue",
                  }}
                  className="u-mar-r_2"
                >
                  {address.address1}
                </Text>}
                <DropdownChevron />
              </ButtonWrapper>
            </div>
            {isMobile() ? (
              <>
                <Stories
                  categories={rests}
                  selectedCategory={savedCategory}
                  setCategory={setCategory}
                />
                <Banner savedCategory={savedCategory} />
              </>
            ) : (
              <div className="u-mar-t_3">
                <Banner savedCategory={savedCategory} />
                <Stories
                  categories={rests}
                  selectedCategory={savedCategory}
                  setCategory={setCategory}
                />
              </div>
            )}
          </div>
          {!isMobile() && (
            <div className="u-mar-t_auto">
              <ButtonWrapper
                onClick={() => {
                  openModal({
                    id: "emaillistmodal",
                    options: {
                      preventBodyScroll: true,
                      centerContent: true,
                      initialHeight: true,
                      className: "discover__email-list",
                    },
                  })
                }}
              >
                <Text
                  style={{ textDecoration: "underline" }}
                  size="s"
                  options={{ weight: "medium", color: "pink", align: "center" }}
                >
                  Stay updated by joining our email list!
                </Text>
              </ButtonWrapper>
            </div>
          )}
        </div>
        {!isLoading && _.isEmpty(rests) ? (
          <EmptyRests address={address} />
        ) : (
          <>
            <RestFeed
              rests={rests[savedCategory]}
              openModal={openModal}
              cat={savedCategory}
              isLoading={isLoading}
            />
          </>
        )}

        <FixedHeader
          key={0}
          showAt={SCROLL_TOP}
          position="bottom"
          options={{
            customPositons: {
              bottom: "1rem",
              right: "1rem",
            },
          }}
        >
          <CSSTransitionGroup
            transitionAppear={true}
            transitionLeave={true}
            transitionEnter={false}
            transitionLeaveTimeout={500}
            transitionAppearTimeout={500}
            transitionName="scroll-top"
          >
            <ButtonWrapper
              onClick={() =>
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
              }
              fillColor="blue"
              aria-label="Scroll back to the top"
              className="discover-scroll-top u-pad_3 flex-row flex-align-center flex-justify-center"
            >
              <BackToTop />
            </ButtonWrapper>
          </CSSTransitionGroup>
        </FixedHeader>
        {isMobile() && (
          <FixedHeader
            showAt={HEADER_HEIGHT}
            transition={{
              transitionAppear: true,
              transitionLeave: true,
              transitionEnter: false,
              transitionLeaveTimeout: 500,
              transitionAppearTimeout: 500,
              transitionName: "discover-header",
            }}
            position="top"
            className={classnames(
              "fill-width u-pad_3 z-1 box bg-white discover-header--fixed",
              {
                "br-bl-20 br-br-20": isMobile(),
              }
            )}
          >
            <div className="flex-row flex-apart flex-align-center fluid-content">
              <Header
                title={address.city}
                size="s"
                options={{ color: "blue", weight: "bold" }}
              />
              <ButtonWrapper
                onClick={() => {
                  openModal({
                    id: "ChangeAddress",
                    customProps: {
                      dropdownSelectCallback: loadDatacb,
                    },
                    options: { preventBodyScroll: true, bgColor: "white" },
                  })
                }}
                className="flex-row flex-align-center"
              >
                <Text
                  size="s"
                  options={{ align: "right", weight: "medium", color: "blue" }}
                  className="u-mar-r_1"
                >
                  {address.address1}
                </Text>
                <DropdownChevron />
              </ButtonWrapper>
            </div>
          </FixedHeader>
        )}
      </div>
    </div>
  )
}

const Banner = ({ savedCategory }) => {
  const props = FoodmojiMap({ name: savedCategory, size: "l" })
  return props ? (
    <div className="local-container u-pad-h_2 u-pad-t_3">
      <LocalBanner {...props} />
    </div>
  ) : null
}

export default Feed
