import React from "react"
import Analytics from "analytics"
import { ReactComponent as FBWhite } from "assets/fb-white.svg"
import { ReactComponent as TwitterWhite } from "assets/twitter-white.svg"
import { ReactComponent as InstaWhite } from "assets/insta-white.svg"

const RestaurantSocials = ({ twitter, facebook, insta, name }) => {
  if (!twitter && !facebook && !insta) {
    return null
  }

  const handleClick = (tag) => {
    Analytics.info(`Restaurant ${tag} clicked`, name)
  }
  return (
    <div className="flex-row u-mar-t_5">
      {facebook && (
        <a
          className="u-mar-h_1 u-pad_1"
          aria-label="Facebook link"
          onClick={() => handleClick("facebook")}
          href={facebook}
        >
          <FBWhite />
        </a>
      )}
      {insta && (
        <a
          className="u-mar-h_1 u-pad_1"
          href={insta}
          onClick={() => handleClick("instagram")}
          aria-label="Instagram link"
        >
          <InstaWhite />
        </a>
      )}
      {twitter && (
        <a
          className="u-mar-h_1 u-pad_1"
          href={twitter}
          onClick={() => handleClick("twitter")}
          aria-label="twitter link"
        >
          <TwitterWhite />
        </a>
      )}
    </div>
  )
}

export default RestaurantSocials
