import React from "react"
import classnames from "classnames"
import { Link } from "react-router-dom"
import { Text, Foodmoji, ButtonWrapper } from "components"
import { isMobile } from "fileConstants"

import HomePageContainer from "./HomePageContainer"

import { ReactComponent as Smile } from "assets/smile.svg"
import { ReactComponent as ChevronRight } from "assets/chevron-right-white.svg"
import Analytics from "analytics"

const HomeThree = ({ size }) => {
  return (
    <HomePageContainer className="bg-blue">
      <div className="flex-row u-mar-t_10">
        <div
          className={classnames("fill-width", {
            "u-mar-r_15": !isMobile(),
          })}
        >
          <Smile />
          <Text
            options={{ color: "white", weight: "extrabold" }}
            size={isMobile() ? "l" : "xl"}
            className="u-mar-t_7"
          >
            Our mission is to connect you with your local restaurants so that
            you can support them by ordering directly.
          </Text>
          <div className="home__foodmojis-container u-mar-t_5">
            <Foodmoji name="Stir_Fry.png" size={size} className="u-mar-h_1" />
            <Foodmoji name="Burger.png" size={size} className="u-mar-h_1" />
            <Foodmoji name="Taco.png" size={size} className="u-mar-h_1" />
          </div>
          <Link
            to="/manifesto"
            className="link-none"
            onClick={() => Analytics.info("Manifesto opened")}
          >
            <ButtonWrapper
              className="flex-row flex-apart flex-align-center u-pad-h_5 u-pad-v_3 br-3 u-mar-t_9 hover-pink fill-width max-90"
              fillColor="pink"
            >
              <Text options={{ weight: "bold", color: "white" }} size="s">
                READ OUR FULL MANIFESTO
              </Text>
              <ChevronRight />
            </ButtonWrapper>
          </Link>
        </div>
        {!isMobile() && (
          <img
            src="https://imgcdn.hngr.co/grid-food.png"
            alt="food emojis"
            className="u-mar-v_auto"
          />
        )}
      </div>
    </HomePageContainer>
  )
}

export default HomeThree
