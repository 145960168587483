import React, { useRef, useLayoutEffect } from "react"
import _ from "lodash"
import classnames from "classnames"
import PropTypes from "prop-types"
import Analytics from "analytics"
import { isMobile } from "fileConstants"
import { Text, FoodmojiMap } from "components"
import "./Stories.scss"

const Stories = (props) => {
  let selectedEl = useRef(null)
  let storyContainerEl = useRef(null)

  useLayoutEffect(() => {
    if (!props.selectedCategory) {
      localStorage.setItem("currentScrollLeft", 0)
    }
    if (storyContainerEl && isMobile()) {
      const sl = localStorage.getItem("currentScrollLeft")
      storyContainerEl.style.overflowX = "scroll"
      // set a timeout so the component has time to render all the stories so the parent component is considered "Scrollable"
      storyContainerEl.scrollLeft = sl
    }
  }, [])

  const selectCategory = (name) => {
    Analytics.info(`Category selected`, name)
    props.setCategory(name)
    if (storyContainerEl && isMobile()) {
      const currentScrollLeft = storyContainerEl.scrollLeft
      localStorage.setItem("currentScrollLeft", currentScrollLeft)
    }
  }

  return (
    <div
      className="stories-container u-pad-v_3"
      ref={(el) => (storyContainerEl = el)}
    >
      {_.keys(props.categories).map((category_name, i) => {
        const isSelected = props.selectedCategory === category_name
        const size = isMobile() ? "s" : "m"
        const Foodmoji = FoodmojiMap({ size, name: category_name })
        return (
          props.categories[category_name].length > 0 && (
            <button
              key={i}
              ref={isSelected ? selectedEl : null}
              className={classnames("story-container u-mar-h_2 u-pad-v_1", {
                "story-container--selected":
                  props.selectedCategory === category_name,
              })}
              onClick={() => selectCategory(category_name)}
            >
              <div
                className={classnames("story-image u-pad_2", {
                  "story-image--selected": isSelected,
                  "story-image--greyed": !isSelected,
                })}
              >
                {Foodmoji.icon}
              </div>
              <Text
                size="s"
                options={{ align: "center", color: "blue" }}
                className={classnames("u-mar-t_3", {
                  "story-text--selected": isSelected,
                })}
              >
                {category_name}
              </Text>
            </button>
          )
        )
      })}
    </div>
  )
}

Stories.defaultProps = {
  categories: {},
  selectedCategory: "",
}

Stories.propTypes = {
  categories: PropTypes.object,
  selectedCategory: PropTypes.string,
  setCategory: PropTypes.func.isRequired,
}

export default Stories
