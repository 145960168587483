import React from "react"
import Analytics from "analytics"
import { ReactComponent as FBWhite } from "assets/fb-white.svg"
import { ReactComponent as TwitterWhite } from "assets/twitter-white.svg"
import { ReactComponent as InstaWhite } from "assets/insta-white.svg"

const Socials = () => {
  const handleClick = (tag) => {
    Analytics.info("Foood social clicked", tag)
  }
  return (
    <div>
      <div className="fluid-content-s flex-row flex-align-center flex-justify-center">
        <a
          className="u-mar-h_1 u-pad_1"
          aria-label="Facebook link"
          onClick={() => handleClick("facebook")}
          href="https://www.facebook.com/Get.Foood/"
        >
          <FBWhite />
        </a>
        <a
          className="u-mar-h_1 u-pad_1"
          href="https://www.instagram.com/get.foood/"
          onClick={() => handleClick("instagram")}
          aria-label="Instagram link"
        >
          <InstaWhite />
        </a>
        <a
          className="u-mar-h_1 u-pad_1"
          href="https://twitter.com/getfoood"
          onClick={() => handleClick("twitter")}
          aria-label="twitter link"
        >
          <TwitterWhite />
        </a>
      </div>
    </div>
  )
}

export default Socials
