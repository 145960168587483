import React, { useState, useEffect } from "react"
import classnames from "classnames"
import { isMobile } from "fileConstants"
import Analytics from "analytics"
import { Text, ButtonWrapper } from "components"
import { ReactComponent as CheckSmall } from "assets/check-small.svg"
import { ReactComponent as CloseBanner } from "assets/close-banner.svg"

const CHECK_SRC = "https://imgcdn.hngr.co/checkmark.png?size=20x20"

const DirectBanner = ({ closeBanner }) => {
  const [show, set] = useState(false)

  useEffect(() => {
    setTimeout(() => set(true), 200)
  }, [])

  const handleClose = () => {
    Analytics.info("Order direct banner closed")
    closeBanner()
  }

  return (
    <div
      className={classnames("bg-pink z-10", {
        "fixed-under-nav fill-width": !isMobile(),
        "nav-margin": isMobile(),
      })}
    >
      <div className="u-pad_3 flex-row flex-align-center fluid-content-l">
        <div className="direct-icon u-mar-r_3">
          <CheckSmall />
        </div>
        <div className="flex-col ">
          <Text
            size="m"
            options={{ weight: "extrabold", color: "white" }}
            className="u-mar-b_1"
          >
            Look for the check!
          </Text>
          <Text size="xs" options={{ color: "white", weight: "bold" }}>
            Restaurants with this check offer direct online ordering. Say
            goodbye to third-parties and hello to supporting small business.
          </Text>
        </div>
        <ButtonWrapper
          onClick={handleClose}
          className="u-mar-b_auto u-mar-l_auto u-pad-l_2 u-pad-b_2"
        >
          <CloseBanner />
        </ButtonWrapper>
      </div>
    </div>
  )
}

export default DirectBanner
