import React, { useEffect, useState, useContext, useRef } from "react"
import { debounce, isEmpty } from "lodash"
import Analytics from "analytics"
import { AppContext } from "context/AppContext"
import { Popover, Text, ButtonWrapper, TopRatedRest, Header } from "components"
import { ReactComponent as ClearIcon } from "assets/clear.svg"
import { ReactComponent as SearchIcon } from "assets/search-bar-icon.svg"
import { ReactComponent as Loader } from "assets/loader.svg"

import { callApi } from "helpers/api"

const SearchBar = ({
  shouldFocus,
  handleChange,
  showHeader,
  noValueHolder,
}) => {
  let searchInput = useRef(null)

  const [pop, setPop] = useState(false)
  const [searchValue, setSearchValue] = useState("")

  const [searchState, setSearchState] = useState({
    isLoading: false,
    rests: null,
  })
  const context = useContext(AppContext)

  useEffect(() => {
    if (shouldFocus) {
      if (searchInput.current) {
        searchInput.current.focus()
      }
    }
  }, [shouldFocus])

  const searchApi = async (query, address) => {
    if (query.length >= 3) {
      if (pop) {
        setPop(false)
      }
      await callApi({
        endpoint: "search",
        url_params: {
          lat: address.lat,
          lng: address.lng,
          query: query,
          type: "search",
          limit: 100,
          logo_required: 0,
          independent_required: 0,
        },
        success: (data) => setSearchState({ isLoading: false, rests: data }),
      })
    } else if (query.length > 0) {
      setPop(true)
    }
  }

  const delayedQuery = useRef(debounce((q, a) => searchApi(q, a), 500)).current

  const handleSearchInput = (e) => {
    if (!searchState.isLoading) {
      setSearchState({
        ...searchState,
        isLoading: true,
      })
    }
    setSearchValue(e.target.value)
    handleChange(e.target.value)
    Analytics.info("Search", e.target.value)
    if (pop) {
      setPop(false)
    }

    delayedQuery(e.target.value, context.address)
  }

  const clearSearch = () => {
    setSearchValue("")
    handleChange("")
    setSearchState({
      isLoading: false,
      rests: null,
    })
    searchInput.current.focus()
  }

  return (
    <div className="fill-width">
      <div className="fill-width p-relative">
        <Popover
          target={searchInput}
          usePropOpen={true}
          position="left"
          isOpen={pop}
          maxWidth={1000}
          innerComponent={
            <Text size="s" options={{ weight: "bold" }} className="u-mar_2">
              Try searching for a little more!
            </Text>
          }
        >
          {() => {
            return (
              <div>
                <SearchIcon className="search-icon" />
                <input
                  id="search"
                  type="search"
                  onChange={handleSearchInput}
                  placeholder="Search for a restaurant"
                  className="search-input fill-width u-pad-v_2 u-pad-l_9 u-pad-r_2"
                  value={searchValue}
                  ref={searchInput}
                />
              </div>
            )
          }}
        </Popover>
        {searchValue && (
          <ButtonWrapper
            className="location-search-clear"
            onClick={clearSearch}
          >
            <ClearIcon />
          </ButtonWrapper>
        )}
      </div>
      {showHeader && (
        <>
          <label htmlFor="search">
            <Header
              options={{ weight: "bold" }}
              className="u-mar-t_3"
              title="Search"
              size="s"
              color="blue"
            />
          </label>
        </>
      )}
      <div className="search-feed-container u-mar-t_3">
        {isEmpty(searchValue) ? (
          noValueHolder
        ) : (
          <SearchResults searchState={searchState} />
        )}
      </div>
    </div>
  )
}

const SearchResults = ({ searchState }) => {
  const { isLoading, rests } = searchState

  if (!isLoading && isEmpty(rests)) {
    return (
      <div className="flex-col flex-justify-center flex-align-center">
        <Text
          size="l"
          options={{ weight: "extrabold", color: "blue", align: "center" }}
          className="u-mar-t_10"
        >
          Oops, we couldn't find that.
        </Text>
        <img
          src="https://imgcdn.hngr.co/sad-plate.png"
          alt="sad plate"
          className="u-mar-h_auto u-mar-t_4"
        />
        <Text
          size="m"
          className="u-mar-t_4"
          options={{ weight: "extrabold", color: "blue", align: "center" }}
        >
          Try searching for another tasty option.
        </Text>
      </div>
    )
  }
  return isLoading ? (
    <div className="flex-row flex-justify-center flex-align-center">
      <Loader />
    </div>
  ) : (
    rests.map((rest, i) => {
      return <TopRatedRest key={i} rest={{ ...rest, direct_ordering: {} }} />
    })
  )
}

SearchBar.defaultProps = {
  handleChange: () => {},
}
export default SearchBar
