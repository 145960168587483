import React, { useEffect, useRef } from "react"
import classnames from "classnames"
import { ButtonWrapper } from "components"
import "./RestImages.scss"
const RestImages = ({ shouldScroll, src, alt }) => {
  let imageRef = useRef(null)
  var timeout

  useEffect(() => {
    if (shouldScroll) {
      document.addEventListener("scroll", animationFrame)

      return () => document.removeEventListener("scroll", animationFrame)
    }
  }, [])

  const animationFrame = (e) => {
    if (timeout) {
      window.cancelAnimationFrame(timeout)
    }

    // Setup the new requestAnimationFrame()
    timeout = window.requestAnimationFrame(function () {
      // Run our scroll functions
      handleScroll(e)
    })
  }
  const handleScroll = (e) => {
    if (imageRef.current) {
      const { current } = imageRef
      current.style.transform = `translateY(${
        -window.scrollY + window.scrollY * 1.4
      }px)`
    }
  }
  return (
    <div>
      <div className="rest-images__container" ref={imageRef}>
        <ButtonWrapper
          onClick={() => console.log("clicked")}
          className="fill-full"
        >
          <div
            className={classnames("rest-page__screenshot-container", {
              "rest-page__screenshot-container--no-image": !src,
            })}
          >
            {src && (
              <img src={src} alt={alt} className="rest-page-screenshot" />
            )}
          </div>
        </ButtonWrapper>
      </div>
    </div>
  )
}

export default RestImages
