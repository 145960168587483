import React, { useContext } from "react"
import { useLocation, useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
import Analytics from "analytics"
import { ModalContext } from "context/ModalContext"
import { ButtonWrapper } from "components"
import { chain } from "lodash"
import { AppContext } from "context/AppContext"
import Socials from "views/Home/Socials"
import { ReactComponent as Foood } from "assets/Foood-header.svg"
import { ReactComponent as CloseIcon } from "assets/close-banner-large.svg"
import { ReactComponent as SearchIcon } from "assets/search-icon.svg"
import { ReactComponent as BackArrow } from "assets/back-arrow.svg"
import { ReactComponent as ShareIcon } from "assets/share.svg"
import "./Navbar.scss"
import { routeNames, isMobile } from "fileConstants"

const Navbar = () => {
  const { openModal, closeCurrentModal, getCurrentModal } = useContext(
    ModalContext
  )

  const app = useContext(AppContext)
  const location = useLocation()
  const isHome = location.pathname === "/" || location.pathname === "/contact"
  const history = useHistory()
  const p = chain(location.pathname).split("/").compact().value()
  const openSearch = () => {
    Analytics.info("Search clicked")

    if (isMobile()) {
      openModal({
        id: "Search",
        options: {
          preventBodyScroll: true,
        },
      })
    } else {
      window.scrollTo(0, 0)
      const s = document.getElementById("search")
      s.focus()
    }
  }

  const closeSearch = () => {
    Analytics.info("Search closed")
    closeCurrentModal()
  }

  const handleShare = () => {
    Analytics.info("Share pressed")

    if (navigator.share) {
      navigator.share({
        title: document.title,
        text: document.title,
        url: window.location.href,
      })
    }
  }

  const handleBack = () => {
    if (app && app.address && app.address.city) {
      //if these are set then that means they have navigated to the rest page frrom the discover page
      history.goBack()
      return
    }
    history.push(routeNames.HOME)
  }

  const HeaderComponent = () =>
    p.length > 2 ? (
      <>
        {isMobile() && (
          <ButtonWrapper onClick={handleBack} aria-label="Go back">
            <BackArrow />
          </ButtonWrapper>
        )}
        {navigator.share && (
          <ButtonWrapper
            onClick={handleShare}
            aria-label="Share"
            className="u-mar-l_auto"
          >
            <ShareIcon />
          </ButtonWrapper>
        )}
      </>
    ) : (
      !isHome && (
        <>
          <ButtonWrapper
            onClick={openSearch}
            className="u-mar-l_auto"
            aria-label="Search"
          >
            <SearchIcon />
          </ButtonWrapper>
        </>
      )
    )

  const isSearchOpen = getCurrentModal() === "Search"
  return (
    <div>
      <nav className="nav-container">
        <div className="nav__content-container u-pad-h_3 flex-apart fluid-content-l">
          <Link
            to={routeNames.HOME}
            onClick={() => {
              Analytics.info(
                "Navbar logo clicked",
                `pathname: ${window.location.pathname}`
              )
              if (getCurrentModal()) {
                closeCurrentModal()
              }

              if (isHome) {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
              }
            }}
          >
            <Foood className={isMobile() ? "absolute-center" : ""} />
          </Link>
          {isHome && !isMobile() ? (
            <Socials className="u-mar-l_auto" />
          ) : isSearchOpen ? (
            <ButtonWrapper onClick={closeSearch} className="u-mar-l_auto">
              <CloseIcon />
            </ButtonWrapper>
          ) : (
            <div className="fill-width flex-row flex-align-center flex-apart  box">
              <HeaderComponent />
            </div>
          )}
        </div>
      </nav>
    </div>
  )
}

export default Navbar
