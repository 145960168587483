import React, { useContext } from "react"
import { Link } from "react-router-dom"
import { round } from "lodash"
import classnames from "classnames"
import Analytics from "analytics"
import { isMobile } from "fileConstants"
import { Text, LazyImage } from "components"
import { ReactComponent as Arrow } from "assets/Arrow.svg"

import "./RestPreviewFull.scss"
import { ModalContext } from "context/ModalContext"

/*
==========================================
This component is deprecated as of 4/9/20
Please use TopRatedRest instead of this component.
==========================================
*/

const RestPreviewFull = ({
  name,
  tags,
  address,
  distance,
  primary_image_url,
  id
}) => {
  const roundedDist = round(distance, 1)
  const size = isMobile() ? "48x48" : "60x60"
  const imgsrc = primary_image_url && `${primary_image_url}?size=${size}`

  const { getCurrentModal } = useContext(ModalContext)
  return (
    <Link
      className="rest-preview-container u-pad-v_3"
      onClick={() => Analytics.info(`Clicked on Restaurant ${name}`, name)}
      to={location => {
        return {
          pathname: `${location.pathname}/${id}`,
          state: {
            fromModal: getCurrentModal() === "Search"
          }
        }
      }}
    >
      <div className="flex-row flex-align-start">
        <div
          className={classnames("rest-preview__image-placeholder", {
            "rest-preview__image--bg": !primary_image_url
          })}
        >
          {imgsrc && <LazyImage src={imgsrc} />}
        </div>
        <div className="u-mar-h_2">
          <Text options={{ weight: "bold", color: "blue" }}>{name}</Text>
          <Text size="s" options={{ weight: "medium", color: "blue" }}>
            {address}
          </Text>
          <div className="flex-row u-mar-t_1">
            {/* {tags.map((tag, i) => (
              <Tag tagName={tag} key={i} />
            ))} */}
          </div>
        </div>
      </div>
      <div className="flex-row flex-align-center">
        <Text
          size="s"
          options={{ weight: "light", color: "blue" }}
          className="u-mar-r_1 no-wrap"
        >
          {roundedDist} mi
        </Text>
        <Arrow />
      </div>
    </Link>
  )
}

RestPreviewFull.defaultProps = {
  primary_image_url: ""
}

export default RestPreviewFull
