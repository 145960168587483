import React, { useEffect, useRef, useState } from "react"
import classnames from "classnames"
import { Text } from "components"
import { Foodmoji } from "components"
import HomePageContainer from "./HomePageContainer"
import { isMobile } from "fileConstants"

import { ReactComponent as SmilePink } from "assets/smile-pink-large.svg"

const HomeTwo = ({ size }) => {
  const [showSteps, setShowSteps] = useState(false)

  let homeTwoRef = useRef(null)
  useEffect(() => {
    let observer
    let didCancel = false

    // set target inside effect so that cleanup function has a reference to it
    const target = homeTwoRef.current

    if (IntersectionObserver && homeTwoRef && homeTwoRef.current) {
      if (IntersectionObserver) {
        observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              // when image is visible in the viewport + rootMargin
              if (entry.isIntersecting && !showSteps) {
                setShowSteps(true)
              }
            })
          },
          {
            threshold: 0.4,
          }
        )
        observer.observe(homeTwoRef.current)
      } else {
        // Old browsers fallback
        // setImgSrc(props.src)
      }
    }

    return () => {
      didCancel = true
      // on component unmount, we remove the listner
      if (observer && observer.unobserve) {
        observer.unobserve(target)
      }
    }
  }, [])
  const DEFAULT_SIZE = isMobile() ? "58x58" : "100x100"
  return (
    <HomePageContainer className="bg-white home-two-container" ref={homeTwoRef}>
      <div className="flex-col u-mar-t_10">
        <div className="home__foodmojis-container">
          <Foodmoji name="Stir_Fry.png" size={size} className="u-mar-h_1" />
          <Foodmoji name="Burger.png" size={size} className="u-mar-h_1" />
          <Foodmoji name="Taco.png" size={size} className="u-mar-h_1" />
        </div>
        <Text
          options={{ weight: "extrabold", color: "pink" }}
          size="l"
          className="home-text u-mar-t_3"
        >
          Where you'll find local restaurants to support.
        </Text>
        {showSteps && (
          <div className={!isMobile() && "flex-row"}>
            <div
              className={classnames(
                "p-relative flex-row u-mar-t_13 flex-align-start animate-in",
                {
                  "flex-col ": !isMobile(),
                }
              )}
            >
              <Text
                options={{ weight: "black", fontSize: "4rem" }}
                style={{ color: "#FE8FB4" }}
              >
                1
              </Text>
              <Foodmoji
                name="Tortilla Chip.png"
                size={DEFAULT_SIZE}
                className="home-two--foodmoji"
              />
              <div
                className={isMobile() ? "max-60 u-mar-l_auto" : "u-mar-t_20"}
              >
                <Text options={{ weight: "bold", color: "blue" }} size="l">
                  Stop searching so hard.
                </Text>
                <Text
                  options={{ weight: "light", color: "blue" }}
                  className="u-mar-t_2"
                >
                  Use our search to find the best restaurants to order direct
                  from in your area.
                </Text>
              </div>
            </div>
            <div
              className={classnames(
                "p-relative flex-row u-mar-t_13 flex-align-start animate-in",
                {
                  "flex-col u-mar-h_10 ": !isMobile(),
                }
              )}
            >
              <Text
                options={{ weight: "black", fontSize: "4rem" }}
                style={{ color: "#FE8FB4" }}
              >
                2
              </Text>
              <Foodmoji
                name="Burger.png"
                size={DEFAULT_SIZE}
                className="home-two--foodmoji"
              />
              <div
                className={isMobile() ? "max-60 u-mar-l_auto" : "u-mar-t_20"}
              >
                <Text options={{ weight: "bold", color: "blue" }} size="l">
                  Wish third-parties farewell.
                </Text>
                <Text
                  options={{ weight: "light", color: "blue" }}
                  className="u-mar-t_2"
                >
                  Discover restaurants, view menus, and click to go to their
                  website to order direct.
                </Text>
              </div>
            </div>
            <div
              className={classnames(
                "p-relative flex-row u-mar-t_13 flex-align-start animate-in",
                {
                  "flex-col ": !isMobile(),
                }
              )}
            >
              <Text
                options={{ weight: "black", fontSize: "4rem" }}
                style={{ color: "#FE8FB4" }}
              >
                3
              </Text>
              <Foodmoji
                name="Milkshake.png"
                size={DEFAULT_SIZE}
                className="home-two--foodmoji"
              />
              <div
                className={isMobile() ? "max-60 u-mar-l_auto" : "u-mar-t_20"}
              >
                <Text options={{ weight: "bold", color: "blue" }} size="l">
                  Put your community first.
                </Text>
                <Text
                  options={{ weight: "light", color: "blue" }}
                  className="u-mar-t_2"
                >
                  Place your order and feel the satisfaction of supporting a
                  local business.
                </Text>
              </div>
            </div>
          </div>
        )}
        <Text
          size="l"
          options={{ weight: "bold", color: "blue", align: "center" }}
          className="u-mar-t_20"
        >
          By ordering direct from the restaurants you crave, you directly help
          them survive & stay afloat.
        </Text>
        <Text
          size="l"
          options={{ weight: "bold", color: "blue", align: "center" }}
          className="u-mar-t_10"
        >
          Now that's goood news.
        </Text>
        <SmilePink className="u-mar-h_auto u-mar-t_8" />
      </div>
    </HomePageContainer>
  )
}

export default HomeTwo
