import React, { useState } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { slice, isEmpty } from "lodash"
import { CSSTransitionGroup } from "react-transition-group"
import Loading from "./Loading"
import { isMobile } from "fileConstants"
import { TopRatedRest, ButtonWrapper, Text } from "components"
import SearchBar from "./SearchBar"

import "./Discover.scss"
import Analytics from "analytics"

// const observer = new IO()
const observer = null
const RestFeed = (props) => {
  const [isSearching, setIsSearching] = useState(false)
  const topRatedIndex = props.rests.length > 4 ? 4 : props.rests.length
  const topRatedRests = props.isLoading
    ? [0, 0, 0, 0]
    : slice(props.rests, 0, topRatedIndex)
  const otherRests = props.isLoading
    ? [0, 0, 0, 0, 0, 0]
    : slice(props.rests, topRatedIndex, props.rests.length)

  const handleSearch = (value) => {
    if (isEmpty(value) && isSearching) {
      setIsSearching(false)
    }
    if (!isEmpty(value) && !isSearching) {
      setIsSearching(true)
    }
  }

  // useEffect(() => {
  //   return () => {
  //     if (props.cat) {
  //       console.log(props.cat)
  //       // observer.reobserve()
  //     }
  //   }
  // }, [props.cat])

  const handleFeedback = () => {
    props.openModal({
      id: "feedback",
      options: {
        preventBodyScroll: true,
        centerContent: true,
        initialHeight: true,
        className: "discover__email-list",
      },
    })
  }

  return (
    <div className="rest-feed__container u-pad-h_3 u-pad-v_4 fill-width">
      {!isMobile() && <SearchBar handleChange={handleSearch} />}
      {!isSearching && (
        <CSSTransitionGroup
          className="fill-width"
          transitionAppear={true}
          transitionEnter={false}
          transitionLeave={false}
          transitionAppearTimeout={500}
          transitionName="rests"
        >
          <div className="rest-feed__top-rated u-mar-t_4 fill-width">
            {topRatedRests.map((rest, i) => {
              return props.isLoading ? (
                <Loading key={i} topRated />
              ) : (
                <TopRatedRest
                  key={i}
                  isTopRated
                  observer={observer}
                  rest={rest}
                />
              )
            })}
          </div>
          {!isEmpty(otherRests) && (
            <div key={0} className="u-mar-t_2">
              {otherRests.map((rest, i) =>
                props.isLoading ? (
                  <Loading key={i} other />
                ) : (
                  <TopRatedRest key={i} observer={observer} rest={rest} />
                )
              )}
            </div>
          )}
        </CSSTransitionGroup>
      )}
      <ButtonWrapper
        border="pink"
        className={classnames(
          "br-4 u-pad_2 fill-width u-mar-b_10 u-mar-t_5 u-mar-h_auto",
          {
            "max-70": !isMobile(),
          }
        )}
        onClick={handleFeedback}
      >
        <Text className="" options={{ weight: "medium", align: "center" }}>
          Got recommendations? We’d love to hear them.
        </Text>
      </ButtonWrapper>
    </div>
  )
}

RestFeed.propTypes = {
  rests: PropTypes.array,
}

RestFeed.defaultProps = {
  rests: [],
}

export default RestFeed
