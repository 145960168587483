import React, { useEffect, useRef, useState } from "react"
import "./LazyImage.css"

const LazyImage = (props) => {
  const [imgSrc, setImgSrc] = useState(null)
  const targetRef = useRef(null)
  useEffect(() => {
    let observer
    let didCancel = false

    // set target inside effect so that cleanup function has a reference to it
    const target = targetRef.current
    if (IntersectionObserver && targetRef && targetRef.current) {
      if (IntersectionObserver) {
        observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              // when image is visible in the viewport + rootMargin
              if (
                !didCancel &&
                (entry.intersectionRatio > 0 || entry.isIntersecting)
              ) {
                setImgSrc(props.src)
              }
            })
          },
          {
            threshold: 0.01,
            rootMargin: "75%",
          }
        )
        observer.observe(targetRef.current)
      } else {
        // Old browsers fallback
        setImgSrc(props.src)
      }
    }
    return () => {
      didCancel = true
      // on component unmount, we remove the listner
      if (observer && observer.unobserve) {
        observer.unobserve(target)
      }
    }
  })
  return imgSrc ? (
    <img data-src={imgSrc} alt={props.alt} {...props} ref={targetRef} />
  ) : (
    <div className="" ref={targetRef} />
  )
}

export default LazyImage
