import React, { useEffect, useRef, useLayoutEffect, useState } from "react"
import { LazyImage } from "components"
import "./AsyncImg.css"

const AsyncImg = ({ src, alt, lazy, className, ...rest }) => {
  const [isReady, setIsReady] = useState(false)
  const [imgSrc, setImgSrc] = useState({ isReady: false, src: src })
  const [dimens, setDimens] = useState({
    width: 0,
    height: 0
  })

  const targetRef = useRef(null)

  /*
  This is run after the content is loaded onto the DOM
  */
  useLayoutEffect(() => {
    getDimens()
  }, [])

  /*
  Run after the dimensions are changed
  */

  useEffect(() => {
    if (dimens.width > 0 || dimens.height > 0) {
      const s = changeSrc(src)
      setImgSrc({
        isReady: true,
        src: s
      })
    }
  }, [dimens])

  const getDimens = () => {
    if (targetRef.current) {
      setDimens({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      })
    }
  }

  const changeSrc = prevSrc => {
    let newSrc = prevSrc.split(".")

    //Iterate later to determine a better way to split and replace cdn url
    if (newSrc[0] !== "https://cdn") {
      return prevSrc
    }
    newSrc[0] = "https://imgcdn"
    newSrc[
      newSrc.length - 1
    ] += `?force_jpeg=1&size=${dimens.width}x${dimens.height}`
    newSrc = newSrc.join(".")
    return newSrc
  }

  // const s = changeSrc(src)

  if (lazy) {
    return imgSrc.isReady ? (
      <LazyImage alt={alt} src={imgSrc.src} className={className} {...rest} />
    ) : (
      <div
        ref={targetRef}
        className={`async-img__element ${className}`}
        {...rest}
      />
    )
  }

  return imgSrc.isReady ? (
    <img
      ref={targetRef}
      alt={alt}
      src={imgSrc.src}
      className={className}
      {...rest}
    />
  ) : (
    <div
      ref={targetRef}
      className={`async-img__element ${className}`}
      {...rest}
    />
  )
}

AsyncImg.propTypes = {}

AsyncImg.defaultProps = {
  className: "",
  alt: "image",
  src: ""
}

export default AsyncImg
