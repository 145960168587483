import React, { useState } from "react"
import classnames from "classnames"
import { Text, ButtonWrapper } from "components"
import { ReactComponent as EmailSignup } from "assets/email-signup.svg"

const EmptyRests = ({ address }) => {
  const [email, setEmail] = useState("")
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState(null)

  const handleInput = (e) => {
    if (error) {
      setError(false)
    }
    setEmail(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!email) {
      setError(true)
      return
    }

    const data = { _replyto: email, zip: address.zipcode }
    const r = await fetch("https://formspree.io/xgelvnor", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
    if (r.status === 200) {
      setSubmitted(true)
    }
  }
  return (
    <div className="rest-feed__container fill-width u-pad_5">
      <div className="u-mar-h_auto flex-col">
        <Text
          options={{ weight: "extrabold", color: "blue", align: "center" }}
          size="l"
        >
          Whoops, looks like we aren't in {address.city} yet.
        </Text>
        <img
          src="https://imgcdn.hngr.co/sad-plate.png"
          alt="sad plate"
          className="u-mar-h_auto u-mar-t_4"
        />
        {!submitted ? (
          <form onSubmit={handleSubmit}>
            <Text
              options={{ weight: "medium", color: "blue", align: "center" }}
              className="u-mar-t_5"
              size="m"
            >
              Sign up for our email updates and we'll notify you when your
              location is live.
            </Text>
            <div
              className={classnames("u-mar-t_7", {
                "home-input--error--red": error,
              })}
            >
              <label htmlFor="signup" hidden>
                Email
              </label>
              <input
                id="signup"
                type="text"
                name="_replyto"
                placeholder="email@example.com"
                className={classnames(
                  "home-sign-up fill-width br-3 u-pad-h_2 u-pad-v_3 ",
                  {}
                )}
                value={email}
                onInput={handleInput}
              />
            </div>
            <ButtonWrapper
              fillColor="pink"
              className="u-pad-h_5 u-pad-v_3 br-3 fill-width u-mar-t_5"
              type="submit"
            >
              <Text options={{ weight: "bold", color: "white" }} size="s">
                SIGN ME UP
              </Text>
            </ButtonWrapper>
          </form>
        ) : (
          <>
            <Text
              options={{ weight: "bold", color: "blue", align: "center" }}
              className="u-mar-t_4"
            >
              Thanks! You're signed up.
            </Text>
            <Text
              options={{ weight: "medium", color: "blue", align: "center" }}
              className="u-mar-t_1"
            >
              Keep your eyes on your inbox for updates.
            </Text>
            <div className="u-mar-h_auto u-mar-t_5 flex-row flex-justify-center">
              <EmailSignup />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default EmptyRests
