import React, { useState } from "react"
import { Text, ButtonWrapper } from "components"
import classnames from "classnames"
import { isMobile } from "fileConstants"
import { ReactComponent as EmailSignup } from "assets/email-signup.svg"
import { ReactComponent as SmileBlue } from "assets/smile-blue.svg"
const HomeSignUp = ({ noMargin, className }) => {
  const [email, setEmail] = useState("")
  const [zip, setZip] = useState("")
  const [error, setError] = useState({})
  const [subimtted, setSubimtted] = useState(false)
  const handleSubmit = async () => {
    if (!email || !zip) {
      setError({ email: !email, zip: !zip })
      return
    }
    const data = { _replyto: email, zip: zip }
    const r = await fetch("https://formspree.io/xgelvnor", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
    if (r.status === 200) {
      setSubimtted(true)
    }
  }

  const handleZip = (e) => {
    if (error.zip) {
      setError({ ...error, zip: false })
    }
    setZip(e.target.value)
  }

  const handleInput = (e) => {
    if (error.email) {
      setError({ ...error, email: false })
    }
    setEmail(e.target.value)
  }
  return (
    <div
      className={classnames("bg-pink", {
        "flex-b-50": !isMobile() && !noMargin,
        "u-pad_4 br-4": isMobile(),
        [className]: className,
      })}
    >
      <div
        className={
          !isMobile() && !noMargin && classnames("u-pad-t_20 u-pad-l_20 max-60")
        }
      >
        <SmileBlue />
        {!subimtted ? (
          <>
            <Text
              options={{ weight: "extrabold", color: "white" }}
              size="l"
              className="u-mar-t_4"
            >
              Hungry for local restaurant news?
            </Text>
            <Text
              options={{ weight: "medium", color: "white" }}
              className="u-mar-t_3"
            >
              Sign up for our emails to stay updated on your neighborhood
              favorites.
            </Text>
            <div
              className={classnames("u-mar-t_7", {
                "home-input--error": error.email,
              })}
            >
              <label htmlFor="signup" hidden>
                Email
              </label>
              <input
                id="signup"
                type="text"
                name="_replyto"
                placeholder="email@example.com"
                className={classnames(
                  "home-sign-up fill-width br-3 u-pad-h_2 u-pad-v_3 ",
                  {}
                )}
                value={email}
                required
                onInput={handleInput}
              />
            </div>

            <div
              className={classnames("u-mar-t_5", {
                "home-input--error": error.zip,
              })}
            >
              <label htmlFor="signup" hidden>
                {" "}
                Zip Code
              </label>
              <input
                id="zipcode"
                type="tel"
                pattern="[0-9]*"
                name="_zipcode"
                placeholder="Enter your zip code"
                className={classnames(
                  "home-sign-up fill-width br-3 u-pad-h_2 u-pad-v_3 ",
                  {}
                )}
                value={zip}
                required
                onInput={handleZip}
              />
            </div>
            <ButtonWrapper
              fillColor="blue"
              className="u-pad-h_5 u-pad-v_3 br-3 fill-width u-mar-t_5"
              onClick={handleSubmit}
              type="submit"
            >
              <Text options={{ weight: "bold", color: "white" }} size="s">
                SIGN ME UP
              </Text>
            </ButtonWrapper>
          </>
        ) : (
          <>
            <Text
              size="l"
              options={{ weight: "bold", color: "white" }}
              className="u-mar-t_4"
            >
              Thanks! You're signed up.
            </Text>
            <Text
              options={{ weight: "medium", color: "white" }}
              className="u-mar-t_3"
            >
              Keep your eyes on your inbox for updates.
            </Text>
            <div className="u-mar-h_auto u-mar-t_13 flex-row flex-justify-center">
              <EmailSignup />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default HomeSignUp
