import ReactGA from "react-ga"
import ReactPixel from "react-facebook-pixel"
import localforage from "localforage"
import * as Sentry from "@sentry/browser"
const uuidv4 = require("uuid/v4")
const { detect } = require("detect-browser")

const browser = detect()
const SESSION_TIMEOUT = 5400000

class AnalyticsClass {
  constructor() {
    this.browserName = null
    this.browserVersion = null
    this.browserOS = null

    if (browser) {
      this.browserName = browser.name
      this.browserVersion = browser.version
      this.browserOS = browser.os
    }

    this.session_id = null
    this.versionId = process.env.REACT_APP_FOOOD_VERSION
    this.googleConversionID = null
    this.gaTrackingID = process.env.REACT_APP_FOOOD_GOOGLE_ANALYTICS_ID
    this.viewportHeight = window.innerHeight
    this.viewportWidth = window.innerWidth
    this.screenHeight = window.screen.height
    this.screenWidth = window.screen.width

    /*
    Initialize functions
    */
    if (this.gaTrackingID) {
      ReactGA.initialize(this.gaTrackingID)
    }

    this.createOrLoadSession()
    this.setHamId()
  }

  utms = () => {
    return {
      utm_source: this.utm_source,
      utm_medium: this.utm_medium,
      utm_campaign: this.utm_campaign,
    }
  }

  /* sets the ham id, which requires a request to the ham server
     which returns the API in the cookie back to the frontend. this 
     would normally not be visible because the ham server is on 
     another host */
  setHamId = () => {
    window.hngr.send('FOOOD', 'SESSION_START', {})
  }

  /* sets the facebook tracking ID but doesnt actually
     make any outbound calls */
  setFacebookTrackingId = (fbTrackingID) => {
    if (fbTrackingID) {
      ReactPixel.init(fbTrackingID)
      this.fbTrackingID = fbTrackingID
    }
  }

  setGoogleConversionId = (id) => {
    if (id && window.dataLayer) {
      this.googleConversionID = id
      window.gtag = function () {
        window.dataLayer.push(arguments)
      }
      window.gtag("set", "linker", { accept_incoming: "true" })
    }
  }

  browser = () => {
    return {
      browserOS: this.browserOS,
      browserName: this.browserName,
      browserVersion: this.browserVersion,
    }
  }

  /* conversion method - this fires off any handlers we have specifically
     connected to a conversion event, such as google tag manager, etc */
  conversion = (orderId, valueAmount) => {
    if (this.googleConversionID && window.gtag) {
      const gtag = window.gtag
      gtag("event", "conversion", {
        send_to: this.googleConversionID,
        transaction_id: orderId,
        value: valueAmount.toString(),
        currency: "USD",
        event_callback: () => {},
      })
    } else {
      console.log("gtag conversion: event not recorded")
    }
  }
  debug = (action, label = null) => {
    this.send(action, label, "debug")
  }

  info = (action, label = null) => {
    this.send(action, label, "info")
  }

  warn = (action, label = null) => {
    this.send(action, label, "warning")
  }

  error = (action, label = null) => {
    this.send(action, label, "error")
  }

  pageview = (url = window.location.pathname) => {
    // if we dont yet have a session id, retry again aggressively
    if (this.session_id === null) {
      setTimeout(() => this.pageview(url), 100)
      return
    }

    ReactGA.pageview(url)
  }

  send = (action, label = null, level = null) => {
    let clabel = null
    if (label === null) {
      clabel = ""
    } else {
      clabel = String(label) // coerce to string here or GA fatals
    }

    // if we dont yet have a session id, retry again aggressvively
    // if (this.session_id === null) {
    //   setTimeout(() => this.send(null, action, label, level), 100)
    //   window.NO_CS_SESSION_ALERT_COUNT += 1
    //   // pick numbers to alert on. THis is to prevent infinte noise but to
    //   // also get a clear picture of whats up
    //   if (
    //     window.NO_CS_SESSION_ALERT_COUNT === 200 ||
    //     window.NO_CS_SESSION_ALERT_COUNT === 1000 ||
    //     window.NO_CS_SESSION_ALERT_COUNT === 10000
    //   ) {
    //     Sentry.configureScope((scope) => {
    //       scope.setExtra("Attempt Number", window.NO_CS_SESSION_ALERT_COUNT)
    //       Sentry.captureMessage("No Clickstream Session Set")
    //     })
    //   }
    //   return
    // }

    let payload = {
      category: "Foood.com",
      action: action,
      label: clabel,
    }

    /* optionally load the event to google or facebook
           depending on whether GA or the pixel is installed */
    if (level !== "debug") {
      if (this.gaTrackingID) {
        ReactGA.event(payload)
      }
      if (this.fbTrackingID) {
        ReactPixel.trackCustom(action, label)
      }
    }
  }

  /* this method sets the session token on this session
     along with the age of the session. after 30 mins, the session
     token gets cleared out and a new analytics session begins */
  async createOrLoadSession() {
    let sessId = await localforage.getItem("sessionId")
    let seen_at = await localforage.getItem("sessionLastSeenAt")
    if (seen_at === null || sessId === null) {
      seen_at = 0
    }
    let utm_source = await localforage.getItem("utm_source")
    let utm_medium = await localforage.getItem("utm_medium")
    let utm_campaign = await localforage.getItem("utm_campaign")
    let initial_referrer = await localforage.getItem("initial_referrer")
    let diner_id = await localforage.getItem("diner_id")

    // handle session timeouts, after a specific period
    // ignore anything we get back from local storage and create
    // a new session id and blow out any stored utms
    const current_timestamp = Date.now()
    const sess_age = current_timestamp - seen_at
    if (sess_age > SESSION_TIMEOUT) {
      sessId = uuidv4()
      utm_source = null
      utm_medium = null
      utm_campaign = null
      initial_referrer = null
    }

    // if we have utm parameters, then update and overwrite
    // anything that we might have set. if null then dont touch
    const params = new URLSearchParams(document.location.search.substring(1))
    const p_utm_source = params.get("utm_source")
    const p_utm_medium = params.get("utm_medium")
    const p_utm_campaign = params.get("utm_campaign")
    if (p_utm_source) {
      utm_source = p_utm_source
    }
    if (p_utm_medium) {
      utm_medium = p_utm_medium
    }
    if (p_utm_campaign) {
      utm_campaign = p_utm_campaign
    }

    const new_ir = initial_referrer ? initial_referrer : this.initial_referrer
    await localforage.setItem("initial_referrer", new_ir)

    // if we have initial_referrer SET and DO NOT have any utms
    // and there ARE utms in the initial_referrer then set
    // those as our initial utms (see #339)
    if (new_ir && new_ir.includes("utm_") && utm_source === null) {
      const rpms = new URLSearchParams(new_ir)
      if (rpms.get("utm_source")) {
        utm_source = rpms.get("utm_source")
      }
      if (rpms.get("utm_campaign")) {
        utm_campaign = rpms.get("utm_campaign")
      }
      if (rpms.get("utm_medium")) {
        utm_medium = rpms.get("utm_medium")
      }
    }

    await localforage.setItem("sessionId", sessId)
    await localforage.setItem("sessionLastSeenAt", current_timestamp)
    await localforage.setItem("utm_source", utm_source)
    await localforage.setItem("utm_medium", utm_medium)
    await localforage.setItem("utm_campaign", utm_campaign)
    if (diner_id !== null) {
      await localforage.setItem("diner_id", diner_id)
    }

    // store this globally so that the api helper file can access it easily
    window.CLICKSTREAM_SESSION_ID = sessId
    window.APP_VERSION = this.versionId
    window.SESSION_ID = sessId
    this.session_id = sessId
    this.utm_source = utm_source
    this.utm_medium = utm_medium
    this.initial_referrer = new_ir
    this.utm_campaign = utm_campaign
  }
}

export default AnalyticsClass
