import routeNames from "./routeNames.js"
import categories from "./categories.js"

const isMobile = () => window.innerWidth <= 900
const primary_blue = "#20205d"
const primary_pink = "#fb0277"

const colors = {
  primary_blue: "#20205d",
  primary_pink: "#fb0277"
}

export { routeNames, categories, isMobile, primary_blue, primary_pink, colors }
