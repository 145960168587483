import React, { useState } from "react"
import classnames from "classnames"
import { isMobile } from "fileConstants"
import { ReactComponent as Close } from "assets/close-modal.svg"
import { ButtonWrapper, Text } from "components"

const Feedback = ({ closeModal }) => {
  const [error, setError] = useState({})
  const [reason, setReason] = useState("")
  const [expected, setExpected] = useState("")
  const [submitted, setSubmitted] = useState(false)

  const handleExpectedInput = (e) => {
    if (error.expected) {
      setError({
        ...error,
        expected: false,
      })
    }

    setExpected(e.target.value)
  }

  const handleReasonInput = (e) => {
    if (error.reason) {
      setError({
        ...error,
        reason: false,
      })
    }

    setReason(e.target.value)
  }

  const submitFeedback = async (e) => {
    e.preventDefault()
    if (!reason || !expected) {
      setError({ expected: !expected, reason: !reason })
      return
    }
    const data = {
      reason: reason,
      expected_result: expected,
      url: window.location.href,
    }
    const r = await fetch("https://formspree.io/xrganknv", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
    if (r.status === 200) {
      setSubmitted(true)
      setTimeout(() => closeModal(), 1500)
    } else if (r.status === 400) {
      setError({ email: true })
    }
  }
  return (
    <div className="p-relative bg-blue discover-modal">
      <div
        className={classnames("u-pad_6 br-6 u-pad-v_8", {
          "u-pad_10": !isMobile(),
        })}
      >
        {!submitted ? (
          <>
            <Text
              options={{
                color: "white",
                weight: "extrabold",
                align: isMobile() ? "center" : "left",
              }}
              as="span"
              size="lxl"
            >
              See something wrong? Let us know.
            </Text>

            <div
              className={classnames("u-mar-t_6", {
                "home-input--error": error.reason,
              })}
            >
              <label htmlFor="name" hidden>
                Reason
              </label>
              <input
                id="reason"
                type="text"
                name="reason"
                placeholder="Ex. missing restaurant, site suggestions, etc."
                className="home-sign-up fill-width br-3 u-pad-h_2 u-pad-v_3 "
                value={reason}
                onInput={handleReasonInput}
              />
            </div>
            <div
              className={classnames("u-mar-t_6", {
                "home-input--error": error.expected,
              })}
            >
              <label htmlFor="expected" hidden>
                Tell us what you expected to find
              </label>
              <textarea
                id="expected"
                type="text"
                name="expected"
                rows="5"
                placeholder="What would you like to tell us?"
                className={classnames(
                  "home-sign-up fill-width br-3 u-pad-h_2 u-pad-v_3 "
                )}
                value={expected}
                onInput={handleExpectedInput}
              />
            </div>

            <ButtonWrapper
              fillColor="pink"
              className="fill-width u-pad-v_3 br-5 u-mar-t_6"
              onClick={submitFeedback}
            >
              <Text
                options={{ weight: "bold", color: "white", align: "center" }}
                size="s"
              >
                SEND FEEDBACK
              </Text>
            </ButtonWrapper>
          </>
        ) : (
          <Text
            options={{ align: "center", weight: "bold", color: "white" }}
            size="xl"
          >
            Thanks for the tasty feedback! We truly appreciate it.
          </Text>
        )}
      </div>

      <ButtonWrapper onClick={closeModal} className="email-list__close u-pad_1">
        <Close fill="#fb0277" stroke="#fb0277" />
      </ButtonWrapper>
    </div>
  )
}

export default Feedback
