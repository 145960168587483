import React, { createContext } from "react"
import { toLower, chain } from "lodash"
import { callApi } from "helpers/api"
import extractAddressComponents from "helpers/googlePlaces"
import { withRouter } from "react-router-dom"

const initialState = {}

export const AppContext = createContext(initialState)

class AppContextProvider extends React.Component {
  constructor(props) {
    super()
    this.state = {
      address: {},
      savedCategory: null,
      reverseGeo: this.reverseGeo,
      setAddress: this.setAddress,
      setInitialAddress: this.setInitialAddress,
      stateReducer: this.stateReducer,
      fetchRests: this.fetchRests,
      isLoading: true,
      rests: {},
    }
  }

  fetchRests = ({ lat, lng }) => {
    if (!this.state.isLoading) {
      this.setState({ isLoading: true })
    }
    callApi({
      endpoint: "rests",
      data: {
        lat,
        lng,
      },
      success: (data) => {
        this.setState({ rests: data.category_places, isLoading: false })
      },
      failure: (err) => {
        console.log("Error getting rests", err)
        alert("error")
      },
    })
  }

  setInitialAddress = () => {
    const query = new URLSearchParams(this.props.location.search)
    const city = chain(this.props.location.pathname)
      .split("/")
      .last()
      .upperFirst()
      .value()
    this.setState({
      address: {
        city: city,
        lat: query.get("lat"),
        lng: query.get("lng"),
        address1: query.get("a"),
      },
    })
  }

  setAddress = (address) => {
    const city = toLower(address.city)
    const a = address.address1 ? address.address1 : ''
    this.setState({ address }, () => {
      this.props.history.push({
        pathname: `/discover/${city}`,
        search: `?lat=${address.lat}&lng=${address.lng}&a=${a}`,
      })
    })
  }

  reverseGeo = ({ coords, callback }) => {
    callApi({
      endpoint: "reverse_geocode",
      success: (data) => this.reverseGeoSuccess(data, callback),
      url_params: coords,
    })
  }

  reverseGeoSuccess = (data, callback) => {
    const components = extractAddressComponents(data.address_components)
    components.lat = data.geometry.location.lat
    components.lng = data.geometry.location.lng
    components.formattedAddress = data.formatted_address
    const a = {
      ...components,
      ...data,
    }
    this.setAddress(a)
    callback(a)
  }

  stateReducer = ({ field, value }) => {
    this.setState({ [field]: value })
  }

  render() {
    return (
      <AppContext.Provider value={this.state}>
        {this.props.children}
      </AppContext.Provider>
    )
  }
}

export default withRouter(AppContextProvider)
