import { isEmpty, find } from "lodash"

const LS_ADDRESS = "foood-recent"

const LocalStorage = {
  _get() {
    return localStorage.getItem(LS_ADDRESS)
  },
  update(data) {
    const recent = this._get()
    let saved = JSON.parse(recent)
    if (isEmpty(saved)) {
      saved = []
    }

    // Check to see if the formatted address is already in localstorage
    // should we use formatted address or place id?
    if (find(saved, ["formattedAddress", data.formattedAddress])) {
      return
    }
    const r = JSON.stringify([data, ...saved])
    localStorage.setItem(LS_ADDRESS, r)
  },
  getRecent() {
    let r = this._get()
    r = JSON.parse(r)
    return r
  },
  clear: async () => {
    await localStorage.removeItem(LS_ADDRESS)
  },

  remove(index) {
    return new Promise((resolve, reject) => {
      try {
        let r = this.getRecent()
        r.splice(index, 1)
        r = JSON.stringify(r)
        localStorage.setItem(LS_ADDRESS, r)
        const newItems = this.getRecent()
        resolve(newItems)
      } catch {
        reject()
      }
    })
  },
}

export default LocalStorage
