import React from "react"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import classnames from "classnames"
import "./Text.scss"
const sizes = {
  xs: "xs",
  s: "s",
  m: "m",
  l: "l",
  lxl: "lxl",
  xl: "xl",
}

const colors = {
  blue: "blue",
  pink: "pink",
  white: "white",
}

const alignments = {
  left: "left",
  center: "center",
  right: "right",
}

const weights = {
  light: "light",
  regular: "regular",
  medium: "medium",
  semibold: "semibold",
  bold: "bold",
  extrabold: "extrabold",
  black: "black",
}

const Text = ({ children, size, className, options, style, as, ...rest }) => {
  const ComponentType = as ? as : "div"
  return (
    <ComponentType
      className={classnames(`a-text a-text--${size}`, {
        [`a-text--${weights[options.weight]}`]: !isEmpty(options.weight),
        [`a-text--${alignments[options.align]}`]: !isEmpty(options.align),
        [`a-text--${colors[options.color]}`]: !isEmpty(options.color),
        [className]: className,
      })}
      style={{
        ...style,
        fontSize: options.fontSize,
      }}
      {...rest}
    >
      {children}
    </ComponentType>
  )
}

Text.propTypes = {
  size: PropTypes.oneOf(Object.keys(sizes)),
  options: PropTypes.shape({
    align: PropTypes.oneOf(Object.keys(alignments)),
    weight: PropTypes.oneOf(Object.keys(weights)),
  }),
}

Text.defaultProps = {
  size: sizes.m,
  options: { weight: weights.medium, align: alignments.left },
}

export default Text
