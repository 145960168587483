import React from "react"
import classnames from "classnames"
import { isMobile } from "fileConstants"

const HomePageContainer = React.forwardRef(
  ({ className, children, ignorePadding, ignoreFluid }, ref) => {
    return (
      <div
        ref={ref}
        className={classnames("fill-width", {
          [className]: className,
        })}
      >
        <div
          className={classnames("p-relative box", {
            "fluid-content-l": !ignoreFluid,
            "u-pad-h_7 u-pad-v_15": !ignorePadding,
            "u-pad-v_20": !ignorePadding && !isMobile(),
          })}
        >
          {children}
        </div>
      </div>
    )
  }
)

export default HomePageContainer
