import React, { useEffect, useState, useRef } from "react"
import classnames from "classnames"
import TextLoop from "react-text-loop"
import {
  Text,
  Header,
  AddressComponents,
  Foodmoji,
  ButtonWrapper,
} from "components"
import Analytics from "analytics"
import { isMobile } from "fileConstants"
//This is just an SVG file that im importing as a react component so i can use the svg inline
import { ReactComponent as Foood } from "assets/Foood.svg"
import { ReactComponent as SmileDesktop } from "assets/home-smile-desktop.svg"
import { ReactComponent as Smile } from "assets/smile.svg"
import { ReactComponent as FBPink } from "assets/fb-pink.svg"
import { ReactComponent as TwitterPink } from "assets/twitter-pink.svg"
import { ReactComponent as InstaPink } from "assets/insta-pink.svg"

import HomeTwo from "./HomeTwo"
import HomeThree from "./HomeThree"
import HomeFour from "./HomeFour"
import HomeFooter from "./HomeFooter"

import "./Home.scss"
const size = "30x30"
const NAV_HEIGHT = 54
const Home = () => {
  //Set the saved category to null so that it only saves
  useEffect(() => {
    window.scrollTo(0, 0)
    localStorage.setItem("saved_category", null)
    Analytics.pageview("Landing")
  }, [])

  const handleScroll = () => {
    window.scrollTo({
      top: window.innerHeight - NAV_HEIGHT,
      left: 0,
      behavior: "smooth",
    })
  }

  return (
    <>
      <div className="home-container fill-width  bg-blue">
        <div
          className={classnames(
            "home__content-container fill-width fluid-content u-pad_4 box",
            {
              "nav-margin": isMobile(),
            }
          )}
        >
          <HomeHeader />

          <AddressComponents
            className={!isMobile() && "u-mar-t_12"}
            align={isMobile() ? "center" : "left"}
          />
          {isMobile() && <Foood className="u-mar-v_10" />}
          <div className={classnames("flex-col flex-align-center bottom", {})}>
            {!isMobile() && (
              <Header
                title="savor your neighborhood."
                size="xs"
                className="home-tag"
                options={{ align: "center", weight: "bold" }}
                style={{ color: "#48488B" }}
              />
            )}

            <ButtonWrapper
              className="home-smile "
              aria-label="scroll to next part"
              onClick={handleScroll}
            >
              <Smile />
            </ButtonWrapper>
          </div>
        </div>
      </div>
      <HomeTwo size={size} />
      <HomeThree size={size} />
      <HomeFour size={size} />
      {isMobile() && (
        <div className="fill-width bg-pink u-pad_5 box">
          <div className="fluid-content-s flex-row flex-align-center flex-justify-center">
            <a
              className="u-mar-h_1 u-pad_1"
              aria-label="Facebook link"
              href="https://www.facebook.com/Get.Foood/"
            >
              <FBPink />
            </a>
            <a
              className="u-mar-h_1 u-pad_1"
              href="https://twitter.com/getfoood"
              aria-label="twitter link"
            >
              <TwitterPink />
            </a>
            <a
              className="u-mar-h_1 u-pad_1"
              href="https://www.instagram.com/get.foood/"
              aria-label="Instagram link"
            >
              <InstaPink />
            </a>
          </div>
        </div>
      )}

      <HomeFooter />
    </>
  )
}

const HomeFoodmojis = () => (
  <div className="home__foodmojis-container">
    <Foodmoji name="Salad.png" size={size} className="u-mar-h_1" />
    <Foodmoji name="Burger.png" size={size} className="u-mar-h_1" />
    <Foodmoji name="Nigiri.png" size={size} className="u-mar-h_1" />
  </div>
)

const HomeHeader = () => {
  const titleArray = [
    "Search, discover, and empower local restaurants by ordering direct.",
    "Support the businesses that make your community awesome.",
    "Ditch third-parties and make a direct impact in your neighborhood.",
  ]

  return (
    <div
      className={classnames("", {
        "flex-row flex-align-center fill-width": !isMobile(),
        "flex-col flex-align-center": isMobile(),
      })}
    >
      {!isMobile() && (
        <div className="flex-row flex-align-center">
          <div className="flex-col flex-align-center">
            <HomeFoodmojis />

            <SmileDesktop className="home-foood-desktop" />
          </div>
          <div className="flex-col flex-align-start u-mar-t_5 u-mar-l_3">
            <TextLoop mask noWrap={false}>
              <TextTransition title={titleArray[0]} />
              <TextTransition title={titleArray[1]} />
              <TextTransition title={titleArray[2]} />
            </TextLoop>
          </div>
        </div>
      )}

      {isMobile() && (
        <>
          <HomeFoodmojis />
          <div>
            <TextLoop noWrap={false} mask>
              <Text
                size="l"
                options={{
                  weight: "bold",
                  align: isMobile() ? "center" : "left",
                }}
                className="u-mar-v_10 home__header"
              >
                {titleArray[0]}
              </Text>
              <Text
                size="l"
                options={{
                  weight: "bold",
                  align: isMobile() ? "center" : "left",
                }}
                className="u-mar-v_10 home__header"
              >
                {titleArray[1]}
              </Text>
              <Text
                size="l"
                options={{
                  weight: "bold",
                  align: isMobile() ? "center" : "left",
                }}
                className="u-mar-v_10 home__header"
              >
                {titleArray[2]}
              </Text>
            </TextLoop>
          </div>
        </>
      )}
    </div>
  )
}

const TextTransition = ({ title }) => (
  <Text
    className="max-90"
    size="m"
    options={{
      weight: "bold",
      color: "white",
      align: isMobile() ? "center" : "left",
    }}
  >
    {title}
  </Text>
)
export default Home
