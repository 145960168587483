import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import "./Header.scss"
const sizes = {
  xs: "xs",
  s: "s",
  m: "m",
  l: "l",
  xl: "xl",
}

const alignments = {
  left: "left",
  center: "center",
  right: "right",
}

const weights = {
  bold: "bold",
  black: "black",
}

const Header = ({ title, size, className, options, ...rest }) => {
  return (
    <div
      className={classnames(
        `header-text header-text--${sizes[size]} header-text--${
          weights[options.weight]
        }`,
        {
          [`a-text--${options.color}`]: options.color,
          [`a-text--${alignments[options.align]}`]: options.align,
          [className]: className,
        }
      )}
      style={{
        fontSize: options.fontSize,
      }}
      {...rest}
    >
      {title}
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(sizes)),
  options: PropTypes.shape({}),
}

Header.defaultProps = {
  title: "",
  size: sizes.m,
  options: {
    weight: weights.bold,
    align: alignments.left,
  },
}

export default Header
