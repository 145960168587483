import React, { useState, useEffect } from "react"
import classnames from "classnames"
import { Link } from "react-router-dom"
import { routeNames, isMobile } from "fileConstants"
import { Text, ButtonWrapper } from "components"
import { ReactComponent as Foood } from "assets/Foood-header.svg"
import Analytics from "analytics"

const inputs = {
  NAME: "name",
  EMAIL: "email",
  MESSAGE: "message",
}

const Contact = () => {
  useEffect(() => {
    Analytics.info("Restaurant contact page opened")
  }, [])
  const [error, setError] = useState({
    name: false,
    email: false,
    message: false,
  })

  const [submitted, setSubmitted] = useState(false)

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const resetError = (field) => {
    setError({
      ...error,
      [field]: false,
    })
  }

  const handleInputs = {
    [inputs.NAME]: (e) => {
      if (error.name) {
        resetError(inputs.NAME)
      }
      setName(e.target.value)
    },
    [inputs.EMAIL]: (e) => {
      if (error.email) {
        resetError(inputs.EMAIL)
      }
      setEmail(e.target.value)
    },
    [inputs.MESSAGE]: (e) => {
      if (error.message) {
        resetError(inputs.MESSAGE)
      }
      setMessage(e.target.value)
    },
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!email || !name) {
      setError({ email: !email, name: !name })
      return
    }
    const data = { _replyto: email, name: name, message: message }
    const r = await fetch("https://formspree.io/maydvykr", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
    if (r.status === 200) {
      setSubmitted(true)
    } else if (r.status === 400) {
      setError({ email: true })
    }
  }
  return (
    <div className="bg-blue fill-full">
      <div className="fluid-content-l flex-col flex-align-center flex-justify-center fill-full u-pad_3 box">
        {!isMobile() && <Foood />}
        {!submitted ? (
          <>
            <Text
              className="u-mar-t_10"
              options={{ weight: "extrabold", align: "center", color: "white" }}
              size={isMobile() ? "l" : "xl"}
            >
              Want to dig in? Fill out the form below.
            </Text>

            <form
              className={classnames("fill-width flex-col", {
                "max-70": !isMobile(),
              })}
              onSubmit={handleSubmit}
            >
              <div
                className={classnames("u-mar-t_7", {
                  "home-input--error": error.name,
                })}
              >
                <label htmlFor="name" hidden>
                  Name
                </label>
                <input
                  id="name"
                  type="text"
                  name="name"
                  placeholder="Your name"
                  className="home-sign-up fill-width br-3 u-pad-h_2 u-pad-v_3 "
                  value={name}
                  onInput={handleInputs[inputs.NAME]}
                />
              </div>
              <div
                className={classnames("u-mar-t_7", {
                  "home-input--error": error.email,
                })}
              >
                <label htmlFor="Email" hidden>
                  Email
                </label>
                <input
                  id="Email"
                  type="text"
                  name="email"
                  placeholder="Your email"
                  className={classnames(
                    "home-sign-up fill-width br-3 u-pad-h_2 u-pad-v_3 ",
                    {}
                  )}
                  value={email}
                  onInput={handleInputs[inputs.EMAIL]}
                />
              </div>
              <div
                className={classnames("u-mar-t_7", {
                  "home-input--error": error.message,
                })}
              >
                <label htmlFor="message" hidden>
                  Your message
                </label>
                <textarea
                  id="message"
                  type="text"
                  name="message"
                  rows="5"
                  placeholder="Your message"
                  className={classnames(
                    "home-sign-up fill-width br-3 u-pad-h_2 u-pad-v_3 ",
                    {}
                  )}
                  value={message}
                  onInput={handleInputs[inputs.MESSAGE]}
                />
              </div>
              <ButtonWrapper
                fillColor="pink"
                type="submit"
                className="br-6 u-pad-h_20 u-pad-v_3 u-mar-h_auto u-mar-t_8"
              >
                <Text options={{ color: "white", weight: "bold" }} size="s">
                  SUBMIT
                </Text>
              </ButtonWrapper>
            </form>
          </>
        ) : (
          <div className="flex-col flex-align-center">
            <Text
              options={{ weight: "bold", align: "center", color: "white" }}
              size="xl"
              className="u-mar-t_5"
            >
              Thanks!
            </Text>
            <Text
              options={{ weight: "semibold", align: "center", color: "white" }}
              size="m"
              className="u-mar-t_5"
            >
              A member of our team will be in touch soon.
            </Text>
            <Link to={routeNames.HOME}>
              <ButtonWrapper
                fillColor="pink"
                className="u-pad-v_3 u-pad-h_10 br-5 u-mar-t_10 u-mar-h_auto"
              >
                <Text
                  options={{ color: "white", align: "right", weight: "bold" }}
                  size="s"
                >
                  Back Home
                </Text>
              </ButtonWrapper>
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default Contact
