import React, { useMemo, useContext, useEffect } from "react"

import { TopRatedRest } from "components"
import IO from "helpers/IO"
import SearchBar from "./SearchBar"
import { AppContext } from "context/AppContext"

const Search = () => {
  const { rests } = useContext(AppContext)

  const observer = new IO()

  useEffect(() => {
    return () => observer.destroy()
  }, [])

  const renderRests = () =>
    Object.values(rests).map((restCategory) => {
      return restCategory.map((rest, i) => {
        return <TopRatedRest key={i} observer={observer} rest={rest} />
      })
    })

  const allRests = useMemo(renderRests, [rests])

  return (
    <div className="bg-white nav-margin fill-height">
      <div className="scroll-y u-pad_3 bg-white fluid-content-m u-mar-h_auto">
        <SearchBar shouldFocus showHeader noValueHolder={allRests} />
      </div>
    </div>
  )
}

export default Search
