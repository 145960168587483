import React from "react"
import { Text, ButtonWrapper } from "components"
import Analytics from "analytics"
import { ReactComponent as PhoneIcon } from "assets/phone.svg"
import { ReactComponent as DirectionIcon } from "assets/directions.svg"
import { ReactComponent as WebsiteIcon } from "assets/website.svg"
import { ReactComponent as MenuIcon } from "assets/menu.svg"

const RestInfoBanner = props => {
  const handleDirectionClick = () => {
    Analytics.info("Restaurant directions clicked")
  }

  const handleWebsiteClick = () => {
    Analytics.info("Restaurant website clicked")
  }

  const handleMenuClick = () => {
    Analytics.info("Restaurant menu clicked")
  }

  const handlePhoneClick = () => {
    Analytics.info("Restaurant phone clicked")
  }
  return (
    <div className="rest-info-banner__container u-pad-v_5 u-pad-h_7 ">
      <div className="flex-row flex-apart u-mar-h_auto">
        <a
          href={`tel:${props.restData.phone_number}`}
          className="rest-info__phone flex-col rest-info__item "
          onClick={handlePhoneClick}
        >
          <div className="flex-col flex-align-center flex-grow">
            <PhoneIcon />
            <Text
              size="s"
              options={{ color: "blue", weight: "light" }}
              className="u-mar-t_auto"
            >
              Call
            </Text>
          </div>
        </a>
        <ButtonWrapper
          className="flex-col rest-info__item"
          onClick={handleDirectionClick}
        >
          <div className="flex-col flex-align-center flex-grow">
            <DirectionIcon />
            <Text
              size="s"
              options={{ color: "blue", weight: "light" }}
              className="u-mar-t_2"
            >
              Directions
            </Text>
          </div>
        </ButtonWrapper>
        <a
          href={props.restData.website}
          onClick={handleWebsiteClick}
          target="_blank"
          rel="noopener noreferrer"
          className="flex-col rest-info__phone rest-info__item "
        >
          <div className="flex-col flex-align-center flex-grow">
            <WebsiteIcon />
            <Text
              size="s"
              options={{ color: "blue", weight: "light" }}
              className="u-mar-t_auto"
            >
              Website
            </Text>
          </div>
        </a>

        <ButtonWrapper
          className="flex-col rest-info__item"
          onClick={handleMenuClick}
        >
          <div className="flex-col flex-align-center flex-grow">
            <MenuIcon />
            <Text
              size="s"
              options={{ color: "blue", weight: "light" }}
              className="u-mar-t_auto"
            >
              Menu
            </Text>
          </div>
        </ButtonWrapper>
      </div>
    </div>
  )
}

RestInfoBanner.defaulProps = {
  restData: {}
}

export default RestInfoBanner
