import "react-app-polyfill/ie11"
import "intersection-observer"
import "url-search-params-polyfill"
import smoothscroll from "smoothscroll-polyfill"
import * as Sentry from "@sentry/browser"

import { Helmet } from "react-helmet"
import React from "react"
import ReactDOM from "react-dom"
import "./index.scss"
import Routes from "./Routes"
import * as serviceWorker from "./serviceWorker"

smoothscroll.polyfill()
Sentry.init({
  dsn:
    "https://bc38cf50cde544bdbd5146035d14048d@o133761.ingest.sentry.io/5211536",
})

ReactDOM.render(
  <React.StrictMode>
    <>
      <Helmet>
        <title>Foood | Find & support your local restaurants</title>
      </Helmet>
      <Routes />
    </>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
