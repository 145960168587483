import React from "react"
import { Text } from "components"
import { isMobile } from "fileConstants"
import "./LocalBanner.scss"

const LocalBanner = (props) => {
  return (
    <>
      {isMobile() && <div className="u-mar-r_3">{props.icon}</div>}
      <div className={isMobile() && "u-mar-h_2"}>
        <Text size="m" options={{ weight: "bold", color: "blue" }}>
          {props.title}
        </Text>
        <Text
          size="s"
          options={{ weight: "regular", color: "blue" }}
          className="u-mar-t_1"
        >
          {props.description}
        </Text>
      </div>
    </>
  )
}

export default LocalBanner
