import React from "react"
import classnames from "classnames"
import "./ButtonWrapper.scss"

const ButtonWrapper = ({
  onClick,
  fillColor,
  filled,
  children,
  textColor,
  border,
  className,
  ...rest
}) => {
  return (
    <button
      onClick={onClick}
      style={{
        color: textColor,
      }}
      {...rest}
      className={classnames(`button-wrapper`, {
        "button-wrapper--filled": filled,
        [`button-wrapper--${fillColor}`]: fillColor,
        [`button-wrapper--border-${border}`]: border,
        [className]: className,
      })}
    >
      {children}
    </button>
  )
}

ButtonWrapper.defaultProps = {
  onClick: () => {},
}

export default ButtonWrapper
