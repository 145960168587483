import React, { useEffect, useState, useContext } from "react"
import LocalStorage from "helpers/LocalStorage"
import Analytics from "analytics"
import { Text, AddressComponents, ButtonWrapper, Header } from "components"
import { ReactComponent as ModalClose } from "assets/modal-close.svg"
import { ReactComponent as RecentIcon } from "assets/recent.svg"
import "./Discover.scss"
import { AppContext } from "context/AppContext"
import { ModalContext } from "context/ModalContext"

const ChangeAddress = ({ dropdownSelectCallback, closeModal }) => {
  const [recent, setRecent] = useState(null)
  const { setAddress } = useContext(AppContext)

  useEffect(() => {
    // Analytics.info("ChangeAddress opened")
  }, [])
  const MC = useContext(ModalContext)
  useEffect(() => {
    if (MC.getCurrentModal() === "ChangeAddress") {
      let recent = LocalStorage.getRecent()
      setRecent(recent)
    }
  }, [MC])
  const cb = (data) => {
    closeModal()
    Analytics.info("Address changed from modal")
    dropdownSelectCallback(data)
  }

  const savedAddress = (address) => {
    Analytics.info("Saved address selected", address.formattedAddress)
    setAddress(address)
    closeModal()
    dropdownSelectCallback(address)
  }

  const removeAddress = async (i, address) => {
    const newList = await LocalStorage.remove(i)
    Analytics.info("Saved address removed", address.formattedAddress)
    setRecent(newList)
  }

  const clearAll = async () => {
    await LocalStorage.clear()
    Analytics.info("Saved addresses cleared")
    setRecent(null)
  }

  const handleClose = () => {
    closeModal()
  }
  return (
    <div
      className="change-address__container u-pad_3 scroll-y box nav-margin
    "
    >
      <div className="fill-width fluid-content-s u-pad-b_20">
        <div className="change-address__header-container u-mar-t_2 u-mar-b_3">
          <Header
            size="s"
            options={{ weight: "bold", align: "left", color: "blue" }}
            className="change-address__header no-wrap"
            title="Change Address"
          />

          <ButtonWrapper onClick={handleClose} className="u-mar-l_auto">
            <ModalClose />
          </ButtonWrapper>
        </div>
        <AddressComponents lightBg dropdownSelectCallback={cb} />
        {recent && (
          <>
            <div className="flex-row flex-apart flex-align-center u-mar-t_7">
              <Header
                title="Recent"
                size="s"
                options={{ weight: "bold", align: "left", color: "blue" }}
              />
              <button
                className="change-address__recent-clear"
                onClick={clearAll}
              >
                <Text options={{ color: "blue", weight: "medium" }} size="xs">
                  Clear All
                </Text>
              </button>
            </div>
            <div className="u-mar-t_3">
              {recent.map((r, i) => {
                return (
                  <div
                    className="flex-row flex-align-center border-bottom fill-width"
                    key={`saved-${i}`}
                  >
                    <ButtonWrapper
                      onClick={() => savedAddress(r)}
                      className="flex-row flex-grow flex-align-center u-pad-v_2"
                    >
                      <RecentIcon />
                      <div className="flex-col flex-align-start u-mar-l_3">
                        <Text
                          size="s"
                          options={{ weight: "bold", color: "blue" }}
                        >
                          {r.address1}
                        </Text>
                        <Text
                          size="s"
                          options={{ color: "blue", weight: "medium" }}
                          className="u-mar-t_1"
                        >
                          {r.city}, {r.state}, {r.zipcode}
                        </Text>
                      </div>
                    </ButtonWrapper>
                    <ButtonWrapper
                      className="u-pad_1"
                      onClick={() => removeAddress(i, r)}
                    >
                      <ModalClose />
                    </ButtonWrapper>
                  </div>
                )
              })}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ChangeAddress
