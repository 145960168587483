import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import { CSSTransitionGroup } from "react-transition-group"
import classnames from "classnames"
import "./FixedHeader.scss"

const FixedHeader = ({
  showAt,
  showInital,
  children,
  hideAt,
  transition,
  options,
  position,
  className,
  ...rest
}) => {
  useEffect(() => {
    window.addEventListener("scroll", scrollHandler)

    return () => window.removeEventListener("scroll", scrollHandler)
  }, [])

  const [fixToTop, setFixToTop] = useState(showInital)
  let timeout

  const scrollHandler = () => {
    // If there's a timer, cancel it
    if (timeout) {
      window.cancelAnimationFrame(timeout)
    }

    // Setup the new requestAnimationFrame()
    timeout = window.requestAnimationFrame(function () {
      // Run our scroll functions
      if (window.scrollY > showAt && !fixToTop) {
        setFixToTop(true)
      }
      if (window.scrollY < showAt) {
        setFixToTop(false)
      }
    })
  }

  if (!isEmpty(transition)) {
    return (
      fixToTop && (
        <CSSTransitionGroup {...transition}>
          <div
            className={classnames("fixed-header nav-margin", {
              [`fixed-header--${position}`]: position,
              [className]: className,
            })}
            key={0}
            {...rest}
            style={{
              ...options.customPositons,
            }}
          >
            {children}
          </div>
        </CSSTransitionGroup>
      )
    )
  }
  return (
    fixToTop && (
      <div
        className={classnames("fixed-header nav-margin", {
          [`fixed-header--${position}`]: position,
          [className]: className,
        })}
        {...rest}
        style={{
          ...options.customPositons,
        }}
      >
        {children}
      </div>
    )
  )
}

FixedHeader.defaultProps = {
  options: {},
  position: "top",
  transition: {},
  showInital: false,
}

FixedHeader.propTypes = {
  position: PropTypes.string,
  showAt: PropTypes.number.isRequired,
  hideAt: PropTypes.number,
  showInital: PropTypes.bool,
}
export default FixedHeader
