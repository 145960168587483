import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Text, ButtonWrapper } from "components"
import { isMobile } from "fileConstants"
import { ReactComponent as FooodLarge } from "assets/foood-blue-xl.svg"
const Manifesto = ({ closeModal }) => {
  const history = useHistory()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="bg-white manifesto-container">
      <div className="fluid-content-l flex-row nav-margin u-pad_10 scroll-y ">
        <div className="flex-col">
          <Text size="l" options={{ weight: "bold", color: "blue" }}>
            Diners now have the ultimate resource to order direct from beloved
            restaurants.
          </Text>
          <Text
            options={{ weight: "medium", color: "blue" }}
            className="u-mar-t_7"
          >
            We started as a team of food industry veterans with a single goal:
            to help the local restaurants we love and give diners a way to
            directly support them.
          </Text>
          <Text
            options={{ weight: "medium", color: "blue" }}
            className="u-mar-t_5"
          >
            So we created Foood.com, connecting diners with great neighborhood
            restaurants while pointing them to a restaurant’s website to order
            direct.
          </Text>
          <Text
            options={{ weight: "medium", color: "blue" }}
            className="u-mar-t_5"
          >
            But why order direct? It’s simple. Third-party services charge high
            fees just for using their platform, making restaurants lose an
            average of $6.00 per order. Ordering direct maximizes the money kept
            by a local business and their employees without having to cut
            corners or sacrifice quality for their diners.
          </Text>
          <Text
            options={{ weight: "medium", color: "blue" }}
            className="u-mar-t_5"
          >
            Try Foood.com. We make it simple to search, discover, and use
            resources to order direct from the best restaurants in your
            community. Join us - we’re all eating la vida local!
          </Text>

          <ButtonWrapper
            fillColor="pink"
            onClick={history.goBack}
            className="u-pad-v_3 u-pad-h_10 br-6 u-mar-h_auto u-mar-t_10 min-50"
          >
            <Text
              options={{ align: "center", color: "white", weight: "bold" }}
              size="s"
            >
              BACK TO FOOOD
            </Text>
          </ButtonWrapper>
        </div>
        {!isMobile() && (
          <div className="flex-col flex-align-center u-mar-l_10">
            <FooodLarge />
            <img
              src="https://imgcdn.hngr.co/grid-food.png"
              alt="food emojis"
              className="u-mar-v_auto u-mar-t_10"
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Manifesto
