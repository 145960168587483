// import everything we need at the beginning of every file

// Import React and its built in tools to parse the JS -> HTML
import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react"
import { useHistory } from "react-router-dom"
// lodash is just a utility library with better functions than vanilla JS
import { isEmpty, toUpper, findIndex, endsWith, random } from "lodash"
import classnames from "classnames"
import { Helmet } from "react-helmet"

import Analytics from "analytics"
import { formatPhoneNumber } from "helpers"
import { AppContext } from "context/AppContext"
import { ModalContext } from "context/ModalContext"
/*
These are custom components that were built
Go to /components/Header/Header.js to see the Header

Making components like this Header are reusable and allow for really fast development
*/
import { RestImages, Header, Text, ButtonWrapper } from "components"
import "./RestInfoBanner"

import { isMobile, routeNames } from "fileConstants"
import { callApi } from "helpers/api"
import AddressBanner from "./AddressBanner"
import RestaurantSocials from "./RestaurantSocials"
import Recommended from "./Recommended"
import { ReactComponent as BackChevron } from "assets/chevron-blue-left.svg"
import { ReactComponent as Smile } from "assets/smile-s.svg"
import { ReactComponent as CheckExtraSmall } from "assets/check-xs.svg"
import { ReactComponent as CheckSmall } from "assets/check-small.svg"
import { ReactComponent as Nologoup } from "assets/no-logo-up.svg"
import { ReactComponent as Nologoright } from "assets/no-logo-right.svg"
import { ReactComponent as Nologoleft } from "assets/no-logo-left.svg"
import { ReactComponent as Nologodown } from "assets/no-logo-down.svg"
import "./Restaurant.scss"

const s = isMobile() ? 90 : 117

const logos = [
  <Nologoup width={s} height={s} />,
  <Nologoright width={s} height={s} />,
  <Nologoleft width={s} height={s} />,
  <Nologodown width={s} height={s} />,
]
/*
This is a functional component, instead of the old class-based components
There's no need to bind functions or worry about 'this' keyword in these
*/

var mouse = {
  _x: 0,
  _y: 0,
  x: 0,
  y: 0,
  updatePosition: function (e) {
    this.x = e.clientX - this._x
    this.y = (e.clientY - this._y) * -1
  },
  setOrigin: function (e) {
    const box = e.getBoundingClientRect()
    this._x = box.left + Math.floor(box.width / 2)
    this._y = box.top + Math.floor(box.height / 2)
    // this._x = e.offsetLeft + Math.floor(e.offsetWidth / 2)
    // this._y = e.offsetTop + Math.floor(e.offsetHeight / 2)
  },
  show: function () {
    return "(" + this.x + ", " + this.y + ")"
  },
}

// match is a prop that is passed in from react router which gives us access to the paramters in the URL
const Restaurant = ({ match }) => {
  const { savedCategory, address } = useContext(AppContext)
  const history = useHistory()
  const { closeCurrentModal, getCurrentModal } = useContext(ModalContext)
  const randomIndex = random(0, 4)
  // here we create a new state variable to store the restaurant data
  // https://reactjs.org/docs/hooks-state.html
  const [restData, setRestData] = useState({})

  // This function is essentially called as soon as the component mounts
  // This is kinda hard to explain and the docs do it much better:
  // https://reactjs.org/docs/hooks-effect.html

  useEffect(() => {
    Analytics.pageview()

    callApi({
      endpoint: "rest-info",
      id: match.params.id,
      // our API helper takes in a function that it calls when the request resolves
      // here we take the returned data and set it to the state variable we initalized about
      success: (data) => {
        setRestData(data.rest_info)
        Analytics.info("Restaurant page loaded", data.rest_info.name)
      },
    })

    //scroll to the top always
    window.scrollTo(0, 0)

    //close any modals
    if (getCurrentModal()) {
      closeCurrentModal()
    }
  }, [match.params.id])

  useEffect(() => {
    if (restData) {
      window.CURRENT_REST = restData.name
    }
  }, [restData])

  const handleWebsiteClick = () => {
    Analytics.info("Restaurant website clicked", restData.name)
  }

  const handlePhoneClick = () => {
    Analytics.info("Restaurant phone clicked", restData.name)
  }

  const handleOrderClick = () => {
    Analytics.info("Restaurant place order clicked", restData.name)
  }

  const handleBack = () => {
    Analytics.info("Back button pressed", restData.name)
    if (address && address.city) {
      //if these are set then that means they have navigated to the rest page frrom the discover page
      history.goBack()
      return
    }
    history.push(routeNames.HOME)
  }

  let laptopEl = useRef(null)
  const laptopRef = useCallback((node) => {
    if (node !== null) {
      laptopEl.current = node
      mouse.setOrigin(node)
      window.mouse = mouse
    }
  }, [])

  const handleMouseMove = (e) => {
    mouse.updatePosition(e)
    let x = (mouse.y / laptopEl.current.offsetHeight / 2).toFixed(2)
    let y = (mouse.x / laptopEl.current.offsetWidth / 2).toFixed(2)
    let style = "rotateX(" + x + "deg) rotateY(" + y + "deg)"
    laptopEl.current.style.transform = style
    laptopEl.current.style.webkitTransform = style
    laptopEl.current.style.mozTransform = style
    laptopEl.current.style.msTransform = style
    laptopEl.current.style.oTransform = style
  }

  var timeout

  const animationFrame = (e) => {
    e.persist()
    if (timeout) {
      window.cancelAnimationFrame(timeout)
    }

    // Setup the new requestAnimationFrame()
    timeout = window.requestAnimationFrame(function () {
      // Run our scroll functions
      handleMouseMove(e)
    })
  }

  const handleMouseLeave = () => {
    let style = "rotateX(" + 0 + "deg) rotateY(" + 0 + "deg)"
    laptopEl.current.style.transform = style
    laptopEl.current.style.webkitTransform = style
    laptopEl.current.style.mozTransform = style
    laptopEl.current.style.msTransform = style
    laptopEl.current.style.oTransform = style
  }

  let splitWebsite

  if (restData.website) {
    try {
      splitWebsite = new URL(restData.website)
      splitWebsite = splitWebsite.hostname
    } catch {
      splitWebsite = restData.website.split("/")
      const i = findIndex(splitWebsite, (str) => endsWith(str, ".com"))
      splitWebsite = splitWebsite[i]
    }
  }
  const size = {
    width: isMobile() ? 90 : 117,
    height: isMobile() ? 90 : 117,
  }
  const imgsrc = restData.primary_image_url
    ? `${restData.primary_image_url}?size=0x${size.height}`
    : null

  const DESKTOP_SIZE = "455x0"
  let screenshotsrc
  if (isMobile()) {
    screenshotsrc =
      restData.screenshots && restData.screenshots.mobile
        ? `${restData.screenshots.mobile}?force_jpeg=1`
        : null
  } else {
    screenshotsrc =
      restData.screenshots && restData.screenshots["desktop-wide"]
        ? `${restData.screenshots["desktop-wide"]}?force_jpeg=1&size=${DESKTOP_SIZE}`
        : null
  }

  const orderLink = restData.website
    ? restData.website
    : `tel:${restData.phone_number}`
  return (
    <div
      className={classnames(
        "rest-page__container fluid-content-l nav-margin box",
        {
          "u-pad_3": !isMobile(),
        }
      )}
    >
      {/*
      Using the lodash utility function, we check to make sure restData is NOT empty before rendering everything
      */}
      {!isEmpty(restData) && (
        <>
          <Helmet>
            <title>{`${restData.name} | Foood`}</title>
          </Helmet>
          {isMobile() ? (
            <MobileRest
              imgsrc={imgsrc}
              screenshotsrc={screenshotsrc}
              size={size}
              city={match.params.city}
              randomIndex={randomIndex}
              address={address}
              handlePhoneClick={handlePhoneClick}
              handleOrderClick={handleOrderClick}
              handleWebsiteClick={handleWebsiteClick}
              orderLink={orderLink}
              splitWebsite={splitWebsite}
              savedCategory={savedCategory}
              restData={restData}
            />
          ) : (
            <>
              <div className="rest-page--desktop z-10 bg-blue br-10">
                <ButtonWrapper
                  onClick={handleBack}
                  className="rest-back flex-row flex-align-center"
                >
                  <BackChevron />
                  <Text
                    options={{ weight: "bold", color: "white" }}
                    className="u-mar-l_2"
                  >
                    Back
                  </Text>
                </ButtonWrapper>
                <div className="u-mar-t_5 box">
                  <div
                    style={{ width: size.width, height: size.height }}
                    className={classnames("rest-page__image o-hidden")}
                  >
                    {imgsrc ? (
                      <img
                        src={imgsrc}
                        alt="logo"
                        className="rest-page__logo"
                      />
                    ) : (
                      logos[randomIndex]
                    )}
                  </div>
                  <div className="flex-col u-mar-t_5">
                    <Header
                      title={restData.name}
                      size="s"
                      options={{ weight: "bold", color: "white" }}
                    />
                    <Text
                      options={{ weight: "semibold", color: "white" }}
                      size="s"
                      className="u-mar-t_1"
                    >
                      {toUpper(savedCategory)}
                    </Text>
                    {restData.pink_checkmark && (
                      <CheckSmall className="u-mar-t_1" />
                    )}
                  </div>
                  <div className="rest-order-button">
                    <a
                      href={orderLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={handleOrderClick}
                      className="rest-info__phone bg-pink u-pad_3 br-6 box flex-row flex-align-center flex-grow flex-justify-center no-wrap u-mar-v_3 button-wrapper--pink"
                    >
                      <Text
                        options={{ color: "white", weight: "bold" }}
                        className="u-mar-l_2"
                        size="m"
                      >
                        {restData.website ? "Place Order" : "Call & Order"}
                      </Text>
                    </a>
                  </div>
                  {restData.website && (
                    <div className="u-mar-t_5 flex-col flex-align-start">
                      <Text options={{ color: "white", weight: "bold" }}>
                        Website
                      </Text>
                      <a
                        href={restData.website}
                        target="_blank"
                        className="rest-link "
                        onClick={handleWebsiteClick}
                      >
                        <Text
                          className="u-mar-t_1"
                          options={{
                            color: "white",
                            weight: "medium",
                            align: "left",
                          }}
                          size="s"
                        >
                          {splitWebsite}
                        </Text>
                      </a>
                    </div>
                  )}
                  {restData.phone_number && (
                    <div className="u-mar-t_5 flex-col flex-align-start">
                      <Text options={{ color: "white", weight: "bold" }}>
                        Phone
                      </Text>
                      <a
                        href={`tel:${restData.phone_number}`}
                        className="link-white"
                        onClick={handlePhoneClick}
                      >
                        <Text
                          options={{
                            color: "white",
                            weight: "medium",
                            align: "left",
                          }}
                          size="s"
                          className="u-mar-t_1"
                        >
                          {formatPhoneNumber(restData.phone_number)}
                        </Text>
                      </a>
                    </div>
                  )}
                  <RestaurantSocials
                    name={restData.name}
                    twitter={restData.twitter_url}
                    insta={restData.insta_url}
                    facebook={restData.facebook_url}
                  />
                  {restData.address1 && (
                    <AddressBanner
                      address={address}
                      restData={restData}
                      desktop
                    />
                  )}
                </div>
              </div>
              <div className="flex-col">
                
                  {screenshotsrc && (
                    <div className="rest-page__screenshot-container">
                    <a
                      href={restData.website}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={handleWebsiteClick}
                    >
                      <div
                        className="rest-page__laptop"
                        onMouseMove={animationFrame}
                        onMouseLeave={handleMouseLeave}
                        ref={laptopRef}
                      >
                        <img
                          src={screenshotsrc}
                          alt={`${restData.name}'s website`}
                          className="rest-page-screenshot"
                        />
                      </div>
                    </a>
                    </div>
                  )}
                
                <div className="u-mar-t_4">
                  <Recommended
                    city={match.params.city}
                    currentId={restData.id}
                    lat={restData.coordinates.latitude}
                    lng={restData.coordinates.longitude}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

const MobileRest = ({
  restData,
  imgsrc,
  randomIndex,
  handlePhoneClick,
  address,
  handleWebsiteClick,
  orderLink,
  splitWebsite,
  city,
  size,
  screenshotsrc,
  savedCategory,
  handleOrderClick,
}) => {
  return (
    <>
      {screenshotsrc && (
        <RestImages
          src={screenshotsrc}
          alt={`${restData.name}'s website`}
          shouldScroll
        />
      )}
      <div className="rest-page__content-container ">
        <div className="u-pad_5">
          {/* Pass in the restaurant name to our header component as a prop */}
          <div className="flex-row flex-apart flex-align-center">
            <div className="flex-col">
              <Header
                title={restData.name}
                size="s"
                options={{ weight: "bold", color: "white" }}
              />
              <Text options={{ weight: "semibold", color: "white" }} size="s">
                {toUpper(savedCategory)}
              </Text>
              {restData.pink_checkmark && (
                <CheckExtraSmall className="u-mar-t_1" />
              )}
            </div>

            <div
              style={{ minWidth: size.width, minHeight: size.height }}
              className={classnames(
                "rest-page__image u-mar-l_3 br-6 o-hidden",
                {
                  "rest-page__no-image": !imgsrc,
                }
              )}
            >
              {imgsrc ? (
                <img src={imgsrc} alt="logo" className="rest-page__logo" />
              ) : (
                logos[randomIndex]
              )}
            </div>
          </div>
        </div>
        {(restData.website || restData.phone_number) && (
          <>
            <div className="rest-order-button flex-row u-pad-v_3 u-mar-h_auto flex-evenly flex-wrap">
              <a
                href={orderLink}
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleOrderClick}
                className="rest-info__phone bg-pink u-pad_4 br-6 box flex-row flex-align-center flex-grow flex-justify-center no-wrap u-mar-v_3 button-wrapper--pink"
              >
                {/* <WebsiteIcon /> */}
                <Text
                  options={{ color: "white", weight: "bold" }}
                  className="u-mar-l_2"
                  size="m"
                >
                  {restData.website ? "Place Order" : "Call & Order"}
                </Text>
              </a>
            </div>

            <div className="bg-blue fill-width u-pad-b_5 u-pad-h_5 box flex-col flex-align-center">
              <Smile className="u-mar-b_3" />
              <a
                href={restData.website}
                target="_blank"
                onClick={handleWebsiteClick}
                className="link-white u-mar-v_2"
              >
                <Text
                  options={{
                    color: "white",
                    weight: "bold",
                    align: "center",
                  }}
                  size="s"
                >
                  {splitWebsite}
                </Text>
              </a>

              <a
                href={`tel:${restData.phone_number}`}
                className="link-white u-mar-v_2"
                onClick={handlePhoneClick}
              >
                <Text
                  options={{
                    color: "white",
                    weight: "bold",
                    align: "center",
                  }}
                  size="s"
                >
                  {formatPhoneNumber(restData.phone_number)}
                </Text>
              </a>
              <RestaurantSocials
                twitter={restData.twitter_url}
                insta={restData.insta_url}
                name={restData.name}
                facebook={restData.facebook_url}
              />
            </div>
          </>
        )}

        {/* <RestInfoBanner res
        <tData={restData} /> */}
        <div className="bg-white">
          {restData.address1 && (
            <AddressBanner address={address} restData={restData} />
          )}
          <div className="u-pad-t_4">
            <Recommended
              city={city}
              currentId={restData.id}
              lat={restData.coordinates.latitude}
              lng={restData.coordinates.longitude}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Restaurant
