import React from "react"
import { ButtonWrapper } from "components"
import HomeSignUp from "views/Home/HomeSignUp"
import { ReactComponent as Close } from "assets/close-modal.svg"

const EmailListModal = ({ closeModal }) => {
  return (
    <div className="p-relative discover-modal">
      <HomeSignUp noMargin className="u-pad_10 br-6" />
      <ButtonWrapper onClick={closeModal} className="email-list__close u-pad_1">
        <Close />
      </ButtonWrapper>
    </div>
  )
}

export default EmailListModal
