import React from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { Home, Discover, Restaurant, Contact } from "./views"
import Manifesto from "views/Home/Manifesto"
import { Navbar } from "./components"
import AppContextProvider from "context/AppContext"
import ModalContextProvider from "context/ModalContext"
import { routeNames } from "./fileConstants"
const Routes = () => {
  const DiscoverPath = routeNames.DISCOVER(":city")
  return (
    <Router>
      <AppContextProvider>
        <ModalContextProvider>
          <Navbar />
          <Switch>
            <Route path={routeNames.HOME} component={Home} exact />

            <Route path={DiscoverPath} component={Discover} exact />
            <Route path="/discover/:city/:id" component={Restaurant} exact />
            <Route path="/restaurant/:id" component={Restaurant} exact />
            <Route path={routeNames.CONTACT} component={Contact} exact />
            <Route path={routeNames.MANIFESTO} component={Manifesto} exact />
          </Switch>
        </ModalContextProvider>
      </AppContextProvider>
    </Router>
  )
}
export default Routes
