import React, { createContext } from "react"
import { Modal } from "components"
import ModalReducer from "./ModalReducer"
import { withRouter } from "react-router-dom"
import Analytics from "analytics"

const initialState = {}

export const ModalContext = createContext(initialState)

class ModalContextProvider extends React.Component {
  constructor() {
    super()
    this.state = {
      stateReducer: this.stateReducer,
      currentModal: null,
      openModal: this.openModal,
      closeCurrentModal: this.closeCurrentModal,
      modalOptions: {},
      isModalOpen: false,
      currentModalName: "",
      getCurrentModal: this.getCurrentModal,
    }
  }

  openModal = ({ options, id, customProps }) => {
    const currentModal = ModalReducer({ id })
    const currentModalWithProps = React.cloneElement(currentModal, {
      ...customProps,
      closeModal: this.closeCurrentModal,
    })

    Analytics.info("Modal opened", id)
    this.setState(
      {
        isModalOpen: true,
        modalOptions: options,
        customProps: customProps,
        stateInFlight: true,
        currentModal: currentModalWithProps,
        currentModalName: id,
      },
      () => {
        const search = this.props.history.location.search
          ? `${
              this.props.history.location.search
            }&modal=${this.getCurrentModal()}`
          : `?modal=${this.getCurrentModal()}`
        // this.props.history.push({
        //   search: search
        // })
        this.setState({ stateInFlight: false })
      }
    )
  }

  getCurrentModal = () => {
    if (!this.state.currentModal) {
      return null
    }
    return this.state.currentModalName
  }

  closeCurrentModal = () => {
    Analytics.info("Modal closed", this.getCurrentModal())
    this.setState({
      isModalOpen: false,
      modalOptions: {},
      currentModal: null,
    })
  }

  stateReducer = ({ field, value }) => {
    this.setState({ [field]: value })
  }

  render() {
    return (
      <ModalContext.Provider value={this.state}>
        {this.props.children}
        {this.state.isModalOpen && (
          <Modal
            options={this.state.modalOptions}
            closeModal={this.closeCurrentModal}
          >
            {this.state.currentModal}
          </Modal>
        )}
      </ModalContext.Provider>
    )
  }
}

export default withRouter(ModalContextProvider)
